<template>
  <form class="p-5" autocomplete="off" v-on:submit.prevent>
    <div class="input-group mb-3">
      <label class="form-check-label text-light" for="rememberMe">
        Please find the confirmation email sent to your email address.
      </label>
    </div>
  </form>
</template>  
<script>
export default {
  name: 'confirmation',
}
</script>
