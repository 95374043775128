import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

// Use Vue cookie
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

import { BootstrapVue } from 'bootstrap-vue'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
// Use BootstrapVue
Vue.use(BootstrapVue)

// Alerts
import VueSweetalert2 from 'vue-sweetalert2';
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2)

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars, faShoppingCart, faUtensils, faTags, faBed, faQrcode, faBinoculars, faHandshake, faHome, faInfo, faMapMarker, faMapMarkerAlt, faChevronRight, faPlus, faSortAlphaDown, faClone, faArrowsAlt, faPencilAlt, faPhone, faArrowLeft, faGlobe, faTimes, faEnvelope, faLock, faTrophy, faCheck, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faBars, faShoppingCart, faUtensils, faTags, faBed, faQrcode, faBinoculars, faHandshake, faHome, faInfo, faMapMarker, faMapMarkerAlt, faChevronRight, faPlus, faSortAlphaDown, faClone, faArrowsAlt, faPencilAlt, faPhone, faArrowLeft, faGlobe, faTimes, faEnvelope, faLock, faTrophy, faCheck, faUser)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// QRC Scanner / Reader
import VueQrcodeReader from "vue-qrcode-reader";
Vue.use(VueQrcodeReader);

Vue.config.productionTip = false
Vue.prototype.$contentApiUrl = window.config.contentApiUrl;
Vue.prototype.$subDomainUrl = window.config.subDomainUrl;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
