var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-breadcrumb',{key:_vm.breadcrumbUpdated,attrs:{"items":_vm.breadcrumbs}}),_c('div',{staticClass:"view-container"},[_c('app-banner',{attrs:{"subRegionID":_vm.subRegionID}}),_c('div',{staticClass:"container"},[_c('b-container',{attrs:{"id":"menu-icons"}},[_c('b-row',_vm._l((_vm.groups),function(group){return _c('b-col',{key:group.ID,staticClass:"menu-item",attrs:{"cols":"3"}},[_c('router-link',{attrs:{"to":{
                path:
                  '/Sites?cid=' +
                  _vm.countryID +
                  '&rid=' +
                  _vm.regionID +
                  '&srid=' +
                  _vm.subRegionID +
                  '&gid=' +
                  group.ID +
                  '&group=' +
                  group.Name,
              },"data-id":group.ID}},[_c('div',{staticClass:"menu-icon"},[_c('img',{attrs:{"src":group.ImgUrl}})]),_c('span',[_vm._v(_vm._s(group.Name))])])],1)}),1)],1)],1),_c('app-bottom-banner')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }