<template>
  <div>
    <!-- Breadcrumb -->
    <app-breadcrumb :items="breadcrumbs" :key="breadcrumbUpdated"></app-breadcrumb>

    <div class="view-container">
      <app-banner :siteID="siteID" :location="location"></app-banner>
      <!-- TODO generate menu-->
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <ul name="listings">
            <li class="menu-item" v-for="i in 10" :key="i">
              <b-skeleton class="skeleton-label" animation="wave" height="50px" width="100%"></b-skeleton>
            </li>
          </ul>
        </template>
        <ul name="listings">
          <li :class="'menu-item ' + listing.Class" v-for="listing in orderedListings" :key="listing.ID">
            <router-link v-if="listing.Class == ''" :to="{ path: '/Listing?sid=' + siteID + '&id=' + listing.ID }">
              {{ listing.Name }}
            </router-link>
            <div v-if="listing.DealsCount > 0" class="dealCountIcon"><span>{{ listing.DealsCount }}</span></div>
            <span v-if="listing.Class != ''">{{ listing.Name }}</span>

            <span v-if="listing.HasDeal" class="badge">1</span>
          </li>
        </ul>
      </b-skeleton-wrapper>
      <ul name="categories">
        <li :class="'menu-item ' + category.Class" v-for="category in orderedCategories" :key="category.ID">
          <router-link v-if="category.Class == ''" :to="{
            path:
              '/Listings?sid=' +
              siteID +
              '&cname=' +
              category.Name +
              '&id=' +
              category.ID,
          }">
            {{ category.Name }}
          </router-link>
          <div v-if="category.DealsCount > 0" class="dealCountIcon"><span>{{ category.DealsCount }}</span></div>
          <span v-if="category.Class != ''">{{ category.Name }}</span>
        </li>
      </ul>
    </div>

    <div class="footer-menu">
      <div class="alpha" @click="showListings()">
        <font-awesome-icon class="fa-icon" icon="sort-alpha-down" size="lg" />
        <span>Alphabet</span>
      </div>
      <div class="categories" @click="showCategories()">
        <font-awesome-icon class="fa-icon" icon="clone" size="lg" />
        <span>Categories</span>
      </div>
    </div>
  </div>
</template>
<!-- Scripts -->
<script>
import BannerPartial from "@/components/BannerPartial.vue";
import BreadcrumbPartial from "@/components/BreadcrumbPartial.vue";

const axios = require("axios");

export default {
  name: "Categories",
  components: {
    "app-banner": BannerPartial,
    "app-breadcrumb": BreadcrumbPartial,
  },
  data() {
    return {
      site: {},
      siteID: parseInt(this.$route.query.sid),
      siteDeals: [],
      groupName: this.$route.query.group,
      groupID: 0,
      groups: {},

      listingCategories: [],
      orderedCategories: [],
      categoriesAtoZ: [],

      listings: [],
      orderedListings: [],
      listingsAtoZ: [],

      navItems: [],
      breadcrumbs: [],
      breadcrumbUpdated: false,
      location: '',
      loading: false
    };
  },
  async created() {
    this.loading = true;

    // Get the site's info
    await this.getSiteInfo();
    // Get the site's menu buttons
    await this.getSiteMenuButtons();
    // Get the group's categories
    await this.getGroupCategories();
    //Get the deals by Site
    await this.getDealsBySite();
    // Get the listings by group
    await this.getListingsByGroup();

    this.loading = false;
  },
  methods: {
    async getSiteInfo() {
      var getSiteUrl = this.$contentApiUrl + "/Sites?id=" + this.siteID;
      var response = await axios.get(getSiteUrl);

      this.site = response.data.Result;
    },
    async getSiteMenuButtons() {
      var getSiteGroupIDUrl =
        this.$contentApiUrl +
        "/Sites/" +
        this.siteID +
        "/Groups" +
        "?name=" +
        this.groupName;

      var siteGroupResponse = await axios.get(getSiteGroupIDUrl);

      this.groupID = siteGroupResponse.data.Result.ID;
      this.breadcrumbs = ["Site", this.site.Name, siteGroupResponse.data.Result.Name];
      this.breadcrumbUpdated = !this.breadcrumbUpdated;
      this.location = this.site.Name;

      // Get the site's menu buttons
      var getSiteMenuUrl =
        this.$contentApiUrl +
        "/Sites/" +
        this.siteID +
        "/Navigation";

      var response = await axios.get(getSiteMenuUrl);
      this.navItems = response.data.Result
    },
    async getGroupCategories() {
      var getSiteListingCategoriesUrl =
        this.$contentApiUrl +
        "/Sites/" +
        this.siteID +
        "/Groups" +
        "?id=" +
        this.groupID;

      var response = await axios.get(getSiteListingCategoriesUrl);
      this.listingCategories = response.data.Result;

      // Create a unique list of Alpha characters from categories array
      for (let index = 0; index < this.listingCategories.length; index++) {
        var name = this.listingCategories[index].Name;
        this.categoriesAtoZ.push(name.charAt(0).toUpperCase());
      }

      this.categoriesAtoZ = [...new Set(this.categoriesAtoZ)];

      for (let index = 0; index < this.categoriesAtoZ.length; index++) {
        var char = this.categoriesAtoZ[index];
        this.orderedCategories.push({
          ID: char,
          Name: char,
          Class: "alpha",
        });

        for (let index = 0; index < this.listingCategories.length; index++) {
          var category = this.listingCategories[index];
          if (category.Name.charAt(0).toUpperCase() == char) {
            this.orderedCategories.push({
              ID: category.ID,
              Name: category.Name,
              Class: "",
              DealsCount: category.DealsCount,
            });
          }
        }
      }
    },
    async getDealsBySite() {
      var getSiteDealsUrl =
        this.$contentApiUrl +
        "/Sites/" +
        this.siteID +
        "/Listings" +
        "/Deals";

      var response = await axios.get(getSiteDealsUrl);
      var data = response.data.Result;
      var filteredDeals = [];

      for (var f = 0; f < data.length; f++) {
        var contains = false;

        for (var fd = 0; fd < filteredDeals.length; fd++)
          if (filteredDeals[fd].ID == data[f].ID) contains = true;

        if (!contains) filteredDeals.push(data[f]);
      }
      this.siteDeals = filteredDeals;
    },
    async getListingsByGroup() {
      var getSiteListingsUrl =
        this.$contentApiUrl +
        "/Sites/" +
        this.siteID +
        "/Groups" +
        "/Listings" +
        "?id=" +
        this.groupID;

      var response = await axios.get(getSiteListingsUrl);
      this.listings = response.data.Result;
      // Create a unique list of Alpha characters from listings array
      for (let index = 0; index < this.listings.length; index++) {
        var name = this.listings[index].Name;
        this.listingsAtoZ.push(name.charAt(0).toUpperCase());
      }

      // Convert array to distinct list
      this.listingsAtoZ = [...new Set(this.listingsAtoZ)];
      for (let index = 0; index < this.listingsAtoZ.length; index++) {
        var char = this.listingsAtoZ[index];
        this.orderedListings.push({
          ID: char,
          Name: char,
          Class: "alpha",
        });
        for (let index = 0; index < this.listings.length; index++) {
          var listing = this.listings[index];
          if (listing.Name.charAt(0).toUpperCase() == char) {
            this.orderedListings.push({
              ID: listing.ID,
              Name: listing.Name,
              DealsCount: listing.DealsCount,
              Class: "",
            });
          }
        }
      }
    },
    showCategories() {
      var categories = document.getElementsByName("categories");
      var listings = document.getElementsByName("listings");
      if (categories[0].style.display != "block") {
        listings[0].style.display = "none";
        categories[0].style.display = "block";
      }
    },
    showListings() {
      var listings = document.getElementsByName("listings");
      var categories = document.getElementsByName("categories");
      if (listings[0].style.display != "block") {
        categories[0].style.display = "none";
        listings[0].style.display = "block";
      }
    },
  },
  mounted() {
    document.getElementById("search").addEventListener("keyup", function () {
      var searchText = document.getElementById("search").value;
      if (searchText) searchText = searchText.trim();

      var li = document.getElementsByClassName("menu-item");
      for (let index = 0; index < li.length; index++) {
        var listing = li[index];
        if (
          listing.textContent.toLowerCase().includes(searchText.toLowerCase())
        )
          listing.style.display = "block";
        else listing.style.display = "none";
      }
    });
  },
};
</script>
<style scoped>
ul[name="categories"] {
  display: none;
}

ul[name="categories"],
ul[name="listings"] {
  padding: 0;
}

ul[name="categories"] li,
ul[name="listings"] li {
  border-bottom: solid 1px #eaeaea;
  padding: 15px;
  font-size: 17px;
  font-weight: 500;
}

ul[name="categories"] li.alpha,
ul[name="listings"] li.alpha {
  background-color: #565656;
  color: white;
  padding: 0px;
  padding-left: 15px;
}

ul[name="categories"] li a,
ul[name="listings"] li a {
  color: black;
  display: block;
  text-decoration: none;
  float: left;
}

div.view-container {
  margin-bottom: 55px;
}

div.footer-menu {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 55px;
  display: flex;
  text-align: center;
  background-color: #565656;
  color: white;
}

div.footer-menu>div {
  flex: 1;
  margin: auto;
}

div.footer-menu>div span {
  display: block;
  font-size: 11px;
}

.badge {
  background: #d2312d;
  color: #fff;
  padding: 3px 5px 3px 5px;
  float: right;
  margin-top: 3px;
}

.dealCountIcon {
  color: #fff;
  background-color: #d61515;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  position: relative;
  left: 90%;
}

.dealCountIcon>span {
  bottom: 25px;
  left: 9px;
  position: relative;
}
</style>