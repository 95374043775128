<template>
  <div>
    <!-- Breadcrumb -->
    <app-breadcrumb :items="breadcrumbs" :key="breadcrumbUpdated"></app-breadcrumb>
    <app-banner :subRegionID="subRegionID" :location="location" :searchText="searchText"></app-banner>
    <div class="view-container">
      <ul class="searchResult">
        <li v-if="sitesAndListings.length == 0" class="spinner">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </li>
        <li v-for="(result, i) in sitesAndListings" :key="i">
          <router-link v-if="result.ResultType == 'Listing'"
            :to="{ path: '/Listing?sid=' + result.SiteID + '&id=' + result.ID }">
            <img :src="apiUrl + '/Sites/Listings/' + result.ID + '/Image/100?w=80&h=80'
              " />
            <div class="detailsContainer">
              <span class="listing">{{ result.Name }}</span>
              <span class="site">{{ result.SiteName }}</span>
              <span class="location">{{ result.RegionName }} - {{ result.SubRegionName }}</span>
              <span class="distance">{{
                result.Distance != null
                ? result.Distance.toFixed(1) + " km"
                : ""
              }}</span>
            </div>
          </router-link>
          <router-link v-else-if="result.ResultType == 'Site'" :to="{ path: '/Site?id=' + result.ID }">
            <img :src="apiUrl + '/Sites/' + result.SiteID + '/Image/Logo?w=80&h=80'
                " />
            <div class="detailsContainer">
              <span class="listing">{{ result.Name }}</span>
              <span class="site">{{ result.SiteName }}</span>
              <span class="location">{{ result.RegionName }} - {{ result.SubRegionName }}</span>
              <span class="distance">{{
                result.Distance != null
                ? result.Distance.toFixed(1) + " km"
                : ""
              }}</span>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<!-- Scripts -->
<script>
import BannerPartial from "@/components/BannerPartial.vue";
import BreadcrumbPartial from "@/components/BreadcrumbPartial.vue";

const axios = require("axios");

export default {
  name: "Site",
  components: {
    "app-banner": BannerPartial,
    "app-breadcrumb": BreadcrumbPartial,
  },
  data() {
    return {
      breadcrumbs: ["Search iPoint"],
      breadcrumbUpdated: false,
      location: '',
      searchText: this.$route.query.q,
      siteID: parseInt(this.$route.query.sid),
      subRegionID: this.$route.query.srid,
      lat: this.$route.query.lat,
      lng: this.$route.query.lng,
      radiusKm: this.$route.query.radiusKm,

      searchResult: [],
      apiUrl: this.$contentApiUrl,
    };
  },
  created() {
    if (!isNaN(this.siteID)) {
      // Get the site's info
      var getSiteUrl =
        this.$contentApiUrl + "/Sites?id=" + this.siteID;
      axios.get(getSiteUrl).then((response) => {
        this.site = response.data.Result;

        this.breadcrumbs = ["Site", this.site.Name, "Search"];
        this.breadcrumbUpdated = !this.breadcrumbUpdated;
        this.location = this.site.Name;
      });
    }

    var getSearchResultUrl =
      this.$contentApiUrl +
      "/Search" +
      "?q=" +
      encodeURIComponent(this.searchText) +
      "&sid=" +
      this.siteID +
      "&srid=" +
      this.subRegionID +
      "&lat=" +
      this.lat +
      "&lng=" +
      this.lng +
      "&radiusKm=" +
      this.radiusKm;
    axios.get(getSearchResultUrl).then((response) => {
      if (response.data.Result.length == 0)
        document.getElementsByClassName("spinner")[0].innerHTML =
          "No results were found, try searching another keyword.";

      this.searchResult = response.data.Result;
    });
  },
  computed: {
    sitesAndListings() {
      return this.searchResult.filter(function (result) {
        return result.ResultType == "Site" || result.ResultType == "Listing";
      });
    },
  },
};
</script>
<!-- Styles -->
<style scoped>
ul.searchResult {
  padding: 0;
}

ul.searchResult li {
  list-style-type: none;
  display: flex;
  border-bottom: 1px solid #cacaca;
  align-items: center;
}

ul.searchResult li.spinner {
  border-bottom: none;
  text-align: center;
  height: 200px;
}

ul.searchResult li.spinner span {
  margin: auto;
  width: 50px;
  height: 50px;
  font-size: 20px;
  color: #369ed5;
}

ul.searchResult li a {
  width: 100%;
  display: inline-flex;
  color: #212529;
  text-decoration: none;
  background-color: transparent;
}

ul.searchResult li img {
  height: 80px;
  width: 80px;
}

ul.searchResult li .detailsContainer {
  display: inline-block;
  width: 100%;
  padding: 10px;
  padding-left: 0px;
}

ul.searchResult li .detailsContainer span.listing {
  display: block;
  font-size: 15px;
  font-weight: 600;
}

ul.searchResult li .detailsContainer span.site {
  display: block;
  font-size: 13px;
  font-weight: 500;
}

ul.searchResult li .detailsContainer span.location {
  display: block;
  font-size: 11px;
}

ul.searchResult li .detailsContainer span.distance {
  display: block;
  float: right;
  font-size: 15px;
  font-weight: 600;
}

div.view-container {
  padding-top: 155px;
}

div#banner-container {
  position: fixed;
  top: 56px;
  width: 100%;
  background-color: white;
}

div#main-breadcrumb {
  position: fixed;
  top: 56px;
  width: 100%;
}
</style>
