<template>
  <div>
    <div class="text-container"><p>{{ error }}</p></div>

    <qrcode-stream @decode="onDecode" @init="onInit" v-if="!destroyed" class="stream">
      <div><img src="@/assets/ScannerBorders.png" class="borders" v-if="!loading && !destroyed"/></div>
      <div class="loading-indicator" v-if="loading">
        Loading...
      </div>
    </qrcode-stream>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'
const axios = require("axios");
export default {

  components: { QrcodeStream },

  data () {
    return {
      result: '',
      error: '',
      loading: false,
      destroyed: false
    }
  },

  methods: {
    onDecode (decodedURL) {      
      axios.get(this.$contentApiUrl + '/CheckQRCURL?qrcurl=' + encodeURIComponent(decodedURL)).then((response) => {
        if(response.data.Success)
          if(response.data.Result.IsRelative)
            window.location.href = response.data.Result.Response;
          else
            window.location = response.data.Result.Response;
        else
          this.error = response.data.Result;
      });
    },

    async onInit (promise) {
        this.loading = true
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permisson"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera found on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: a secure 'https' connection was required"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: the camera might already be in use"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: the browser you are using is not supported"
        }
      } finally {
          this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.text-container{
  margin: 15px;
  padding: 10px;
}
.text-container > p {
  color: red;
  text-align: center;
  line-height: 0;
}
.loading-indicator {
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
}
.borders{
  width: 50%;
  display: block;
  top: 30%;
  left: 25%;
  position: absolute;
}
.stream{
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
</style>