var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-breadcrumb',{key:_vm.breadcrumbUpdated,attrs:{"items":_vm.breadcrumbs}}),_c('div',{staticClass:"view-container"},[_c('app-banner',{attrs:{"siteID":_vm.siteID,"location":_vm.location}}),_c('b-skeleton-wrapper',{attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('ul',{attrs:{"name":"listings"}},_vm._l((10),function(i){return _c('li',{key:i,staticClass:"menu-item"},[_c('b-skeleton',{staticClass:"skeleton-label",attrs:{"animation":"wave","height":"50px","width":"100%"}})],1)}),0)]},proxy:true}])},[_c('ul',{attrs:{"name":"listings"}},_vm._l((_vm.orderedListings),function(listing){return _c('li',{key:listing.ID,class:'menu-item ' + listing.Class},[(listing.Class == '')?_c('router-link',{attrs:{"to":{ path: '/Listing?sid=' + _vm.siteID + '&id=' + listing.ID }}},[_vm._v(" "+_vm._s(listing.Name)+" ")]):_vm._e(),(listing.DealsCount > 0)?_c('div',{staticClass:"dealCountIcon"},[_c('span',[_vm._v(_vm._s(listing.DealsCount))])]):_vm._e(),(listing.Class != '')?_c('span',[_vm._v(_vm._s(listing.Name))]):_vm._e(),(listing.HasDeal)?_c('span',{staticClass:"badge"},[_vm._v("1")]):_vm._e()],1)}),0)]),_c('ul',{attrs:{"name":"categories"}},_vm._l((_vm.orderedCategories),function(category){return _c('li',{key:category.ID,class:'menu-item ' + category.Class},[(category.Class == '')?_c('router-link',{attrs:{"to":{
          path:
            '/Listings?sid=' +
            _vm.siteID +
            '&cname=' +
            category.Name +
            '&id=' +
            category.ID,
        }}},[_vm._v(" "+_vm._s(category.Name)+" ")]):_vm._e(),(category.DealsCount > 0)?_c('div',{staticClass:"dealCountIcon"},[_c('span',[_vm._v(_vm._s(category.DealsCount))])]):_vm._e(),(category.Class != '')?_c('span',[_vm._v(_vm._s(category.Name))]):_vm._e()],1)}),0)],1),_c('div',{staticClass:"footer-menu"},[_c('div',{staticClass:"alpha",on:{"click":function($event){return _vm.showListings()}}},[_c('font-awesome-icon',{staticClass:"fa-icon",attrs:{"icon":"sort-alpha-down","size":"lg"}}),_c('span',[_vm._v("Alphabet")])],1),_c('div',{staticClass:"categories",on:{"click":function($event){return _vm.showCategories()}}},[_c('font-awesome-icon',{staticClass:"fa-icon",attrs:{"icon":"clone","size":"lg"}}),_c('span',[_vm._v("Categories")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }