<template>
    <div>
        <!-- Breadcrumb -->
        <app-breadcrumb v-bind:items="breadcrumbs" :key="breadcrumbUpdated"></app-breadcrumb>

        <div class="view-container">
            <div class="generic" id="gift-card-image">
                <div id="card-body">
                    <label class="generic">Gift Card Number</label>
                    <img id="bow-image" src="../assets/red-bow.png" />
                    <input class="generic" id="gcnumber" type="text" v-model="gcInputNumber" placeholder="e.g 123456" />
                    <label class="generic" id="balance">{{ gcResponse }}</label>
                </div>
                <button :disabled="gcInputNumber != '' ? false : true" class="btn btn-secondary" id="check-btn"
                    @click="GetBalance(gcInputNumber)">
                    Check Balance
                </button>
            </div>
            <app-bottom-banner :siteID="siteID"></app-bottom-banner>
        </div>
    </div>
</template>
<script>
import BreadcrumbPartial from "@/components/BreadcrumbPartial.vue";
import AdBannerPartial from "@/components/AdBannerPartial.vue";

const axios = require("axios");

export default {
    name: "Giftcard",
    components: {
        "app-breadcrumb": BreadcrumbPartial,
        "app-bottom-banner": AdBannerPartial,
    },
    mixins: [],
    data() {
        return {
            siteID: parseInt(this.$route.query.id),
            breadcrumbs: [],
            breadcrumbUpdated: false,
            gcInputNumber: "",
            gcResponse: "",
        };
    },
    created() {
        var _self = this;
        // Get the site's info
        var getSiteUrl = this.$contentApiUrl + "/Sites?id=" + this.siteID;
        axios.get(getSiteUrl).then((response) => {
            _self.breadcrumbs = ["Site", response.data.Result.Name, "Giftcard"];
            _self.breadcrumbUpdated = !_self.breadcrumbUpdated;
        });
    },
    methods: {
        GetBalance(gcnumber) {

            if (!gcnumber)
                return;

            gcnumber = gcnumber.trim();

            var _self = this;
            var url =
                this.$contentApiUrl +
                "/Sites/" +
                this.siteID +
                "/GiftCard/BalanceEnquiry?voucherNumber=" +
                gcnumber;
            axios.get(url).then((response) => {
                if (response.data.Result.Message !== null)
                    _self.gcResponse = response.data.Result.Message;
                else
                    _self.gcResponse = _self.format((response.data.Result.Balance/100)); // Balance is returned in cents
            });
        },
        format(number) {
            return 'R ' + number.toLocaleString(undefined,{ minimumFractionDigits: 2 });
        }
    },
};
</script>
<style>
.view-container {
    display: grid;
}

.generic {
    text-align: center;
    margin: auto;
    padding: 1px;
}

#gift-card-image {
    margin-top: 63px;
}

#card-body>label {
    color: white;
    text-shadow: -1px -1px 0px rgba(255, 255, 255, 0.3), 1px 1px 0px rgba(0, 0, 0, 0.8);
}

#card-body {
    display: flex;
    flex-direction: row;
    height: 175px;
    width: 275px;
    padding-top: 10px;
    background-color: #41a3db;
    border-radius: 20px;
    box-shadow: 0px 1px 3px 0px;
    margin-bottom: 15px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
}

#bow-image {
    position: absolute;
    height: 80px;
    width: 80px;
    top: 145px;
}

#gcnumber {
    margin-top: 4px;
    border-style: hidden;
    width: 100%;
    height: 35px;
}

#balance {
    margin-top: 20px;
}
</style>
