<template>
  <div>
    <b-card class="mb-2">
      <b-card-text>
        <b-row>
          <p class="info">App version: v{{ appVersion }}</p>
        </b-row>
      </b-card-text>

      <b-row style="text-align:center">
        <b-button id="btnBack" href="/" variant="outline-primary">Go Back</b-button>
      </b-row>
    </b-card>
  </div>
</template>
<!-- Scripts -->
<script>
const version = require("../../package.json").version;
export default {
  data() {
    return {
      appVersion: ""
    };
  },
  mounted() {
    this.appVersion = version;
  }
};
</script>
<!-- Styles -->
<style scoped>
.info {
  display: block;
  width: 160px;
  margin: 0 auto;
  margin-bottom: 10px;
}
#btnBack {
  margin: 0 auto;
}
</style>