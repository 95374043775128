<template>
  <b-modal id="loginModal" centered hide-header hide-footer modal-class="login-modal">
    <div class="card shadow-lg border-2">
      <div class="card-header text-center p-3">
        <button class="close-modal" @click="closeLoginModal()">
          <font-awesome-icon class="fa-icon" icon="times" />
        </button>
        <h1 v-if="isConfirmation" class="card-title text-light">THANK YOU</h1>
        <h1 v-else-if="isLogin" class="card-title text-light">WELCOME</h1>
        <h1 v-else class="card-title text-light">JOIN US!</h1>
      </div>
      <b-collapse id="collapse-confirm" v-model="isConfirmation">
        <div class="card-body p-0">
          <confirmation></confirmation>
        </div>
      </b-collapse>
      <b-collapse v-model="isLogin" visible id="collapse-singin">
        <div class="card-body p-0">
          <sign-in @logedIn="logedIn"></sign-in>
        </div>
      </b-collapse>
      <div class="card-footer text-center p-0">
        <button class="btn border-0 btnOffset text-light toggle-button" @click="toggleState()">
          <span v-if="isLogin"><strong>Sign-Up</strong></span>
          <span v-else><strong>Sing-In</strong></span>
        </button>
        <b-collapse v-model="isSignUp" id="collapse-singup">
          <div class="p-0">
            <sign-up @updateIsConformation="updateIsConformation()"></sign-up>
          </div>
        </b-collapse>
      </div>
    </div>
  </b-modal>
</template>
  
<script>
import signIn from "./singIn.vue"
import signUp from "./signUp.vue"
import confirmation from "./confirmation.vue"
export default {
  name: 'loginMain',
  components: {
    signIn,
    signUp,
    confirmation,
  },
  data() {
    return {
      isLogin: true,
      isSignUp: false,
      isConfirmation: false,
      returnURL: '',
    }
  },
  created() {
    this.$root.$on('showLoginModal', (returnURL) => this.logoutEventHandler(returnURL))
  },
  methods: {
    toggleState() {
      if (this.isConfirmation) {
        this.isLogin = true;
        this.isSignUp = false;
        this.isConfirmation = false;
      } else {
        this.isLogin = !this.isLogin;
        this.isSignUp = !this.isSignUp;
      }
    },
    updateIsConformation() {
      this.isConfirmation = true;
      this.isLogin = false;
      this.isSignUp = false;
    },
    closeLoginModal() {
      this.$bvModal.hide("loginModal");
    },
    logedIn() {
      if (this.returnURL)
        this.$emit('logedIn', this.returnURL);
    },
    logoutEventHandler(returnURL) {
      this.returnURL = returnURL;
      this.$bvModal.show("loginModal");
    }
  },
}

</script>
  
<style>
#loginModal .card {
  background: linear-gradient(346deg, rgba(0, 12, 160, 0.7233018207282913) 0%, rgba(35, 204, 236, 0.8045343137254902) 100%);
  border: none;
}

#loginModal .card .card-title {
  font-size: 3rem;
}

#loginModal .card .toggle-button {
  background: linear-gradient(90deg, rgba(6, 154, 231, 1) 0%, rgba(35, 204, 236, 1) 100%);
}

::placeholder {
  color: lightgray !important;
  opacity: 1;
}

.transparent-background {
  background: transparent !important;
}

@keyframes shake {
  0% {
    transform: rotate(0deg)
  }

  10% {
    transform: rotate(-10deg)
  }

  20% {
    transform: rotate(10deg)
  }

  30% {
    transform: rotate(-10deg)
  }

  40% {
    transform: rotate(10deg)
  }

  50% {
    transform: rotate(-10deg)
  }

  60% {
    transform: rotate(10deg)
  }

  70% {
    transform: rotate(-10deg)
  }

  80% {
    transform: rotate(10deg)
  }

  90% {
    transform: rotate(-10deg)
  }

  100% {
    transform: rotate(0deg)
  }

}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s !important;
}

.login-modal .modal-body {
  padding: 0;
}

.login-modal .modal-body .close-modal {
  border: none;
  background-color: transparent;
  float: right;
  margin: -12px;
  transition: all ease-in-out 0.2s;
}

.login-modal .modal-body .close-modal:active,
.login-modal .modal-body .close-modal:hover {
  transform: scale(1.1);
}

.login-modal .modal-body .close-modal:focus {
  border-style: none;
  outline: none;
}

.shake {
  animation: shake 1s;
  animation-iteration-count: 1;
}

.redShadow:focus {
  box-shadow: 0 0 10px red !important;
}

.btnOffset {
  position: relative;
  top: -18px;
}

.login-error-text {
  color: rgb(255 159 157);
}
</style>