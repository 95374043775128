<template>
  <div>
    <!-- Breadcrumb -->
    <app-breadcrumb :items="breadcrumbs" :key="breadcrumbUpdated"></app-breadcrumb>
    <div class="view-container">
      <app-banner :siteID="siteID" :location="location"></app-banner>
      <!-- TODO generate menu-->
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <ul name="listings">
            <li class="menu-item" v-for="i in 10" :key="i">
              <b-skeleton class="skeleton-label" animation="wave" height="50px" width="100%"></b-skeleton>
            </li>
          </ul>
        </template>
        <ul name="listings">
          <li :class="'menu-item ' + listing.Class" v-for="listing in orderedListings" :key="listing.ID">
            <router-link v-if="listing.Class == ''" :to="{ path: '/listing?sid=' + siteID + '&id=' + listing.ID }">
              {{ listing.Name }}
            </router-link>
            <div v-if="listing.DealsCount > 0" class="dealCountIcon"><span>{{ listing.DealsCount }}</span></div>
            <span v-if="listing.Class != ''">{{ listing.Name }}</span>
          </li>
        </ul>
      </b-skeleton-wrapper>
    </div>
  </div>
</template>
<!-- Scripts -->
<script>
import BannerPartial from "@/components/BannerPartial.vue";
import BreadcrumbPartial from "@/components/BreadcrumbPartial.vue";

const axios = require("axios");

export default {
  name: "Listings",
  components: {
    "app-banner": BannerPartial,
    "app-breadcrumb": BreadcrumbPartial,
  },
  data() {
    return {
      site: {},
      siteID: parseInt(this.$route.query.sid),
      category: this.$route.query.cname,
      categoryID: parseInt(this.$route.query.id),
      groups: {},

      listingCategories: [],

      listings: [],
      orderedListings: [],
      listingsAtoZ: [],

      navItems: [],
      breadcrumbs: [],
      breadcrumbUpdated: false,
      location: '',
      loading: false
    };
  },
  async created() {
    this.loading = true;

    //Get the site's info
    await this.getSiteInfo();
    // Get the listings by group
    await this.getListingsByGroup();

    this.loading = false;
  },
  methods: {
    async getSiteInfo() {
      var getSiteUrl = this.$contentApiUrl + "/Sites?id=" + this.siteID;
      var response = await axios.get(getSiteUrl);

      this.site = response.data.Result;
      this.breadcrumbs = ["Site", this.site.Name, this.category];
      this.breadcrumbUpdated = !this.breadcrumbUpdated;
      this.location = this.site.Name;
    },
    async getListingsByGroup() {
      var getSiteListingsUrl =
        this.$contentApiUrl +
        "/Sites/" +
        this.siteID +
        "/Groups" +
        "/Categories" +
        "?id=" +
        this.categoryID;

      var response = await axios.get(getSiteListingsUrl)
      this.listings = response.data.Result;

      // Create a unique list of Alpha characters from listings array
      for (let index = 0; index < this.listings.length; index++) {
        var name = this.listings[index].Name;
        this.listingsAtoZ.push(name.charAt(0).toUpperCase());
      }

      this.listingsAtoZ = [...new Set(this.listingsAtoZ)];

      for (let index = 0; index < this.listingsAtoZ.length; index++) {
        var char = this.listingsAtoZ[index];
        this.orderedListings.push({
          ID: char,
          Name: char,
          Class: "alpha",
        });

        for (let index = 0; index < this.listings.length; index++) {
          var listing = this.listings[index];
          if (listing.Name.charAt(0).toUpperCase() == char) {
            this.orderedListings.push({
              ID: listing.ID,
              Name: listing.Name,
              Class: "",
              DealsCount: listing.DealsCount,
            });
          }
        }
      }
    }
  }
};
</script>
<style scoped>
ul[name="listings"] {
  padding: 0;
}

ul[name="listings"] li {
  border-bottom: solid 1px #eaeaea;
  padding: 15px;
  font-size: 17px;
  font-weight: 500;
}

ul[name="listings"] li.alpha {
  background-color: #565656;
  color: white;
  padding: 0px;
  padding-left: 15px;
}

ul[name="listings"] li a {
  color: black;
  display: block;
  text-decoration: none;
  float: left;
}

.dealCountIcon {
  color: #fff;
  background-color: #d61515;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  position: relative;
  left: 90%;
}

.dealCountIcon>span {
  bottom: 25px;
  left: 9px;
  position: relative;
}
</style>