var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('loginMain'),(_vm.deals.length == 0)?_c('span',{staticStyle:{"display":"block","text-align":"center"}},[_c('br'),_vm._v(" There are no deals available at this time. ")]):_vm._e(),_c('ul',{staticClass:"deal-list"},_vm._l((_vm.deals),function(deal){return _c('li',{key:deal.ID},[_c('router-link',{attrs:{"to":{
          path: '/Deal?did=' + deal.ID + '&sid=' + _vm.siteID,
        }}},[_c('div',{staticClass:"deal-banner-container",style:('background-image: url(' +
          _vm.apiUrl +
          '/Sites' +
          '/Listings' +
          '/Deals/' +
          deal.ID +
          '/Image/Ad)')}),_c('hr'),(deal.StockRemaining == 0 && deal.StockCount != 0)?_c('span',{staticClass:"deal-heading out-of-stock"},[_vm._v("Out of Stock")]):_vm._e(),_c('span',{staticClass:"deal-heading"},[_vm._v(_vm._s(deal.Name))]),_c('br'),(deal.DealText)?_c('span',{staticClass:"deal-text"},[_vm._v(_vm._s(deal.DealText))]):_vm._e(),(deal.DealText)?_c('br'):_vm._e(),_c('div',{staticClass:"price"},[_c('span',{staticClass:"deal-text"},[_c('strike',[_vm._v("R"+_vm._s((deal.RetailPrice != undefined ? deal.RetailPrice : 0 ).toFixed(2)))])],1),_vm._v("   "),_c('span',{staticClass:"deal-subheading"},[_vm._v("R"+_vm._s((deal.DealPrice != undefined ? deal.DealPrice : 0).toFixed(2)))])])])],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }