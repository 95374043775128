var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ul',{staticClass:"site-list"},_vm._l((_vm.sites),function(site){return _c('li',{key:site.ID},[_c('router-link',{attrs:{"to":'/categories?sid=' + site.ID + '&group=' + _vm.groupName}},[_c('div',{staticClass:"site-banner-container",style:('background-image: url(' +
            _vm.apiUrl +
            '/Sites/' +
            site.ID +
            '/Image/Banner)')},[_c('div',{staticClass:"site-logo-container",style:('background-image: url(' +
              _vm.apiUrl +
              '/Sites/' +
              site.ID +
              '/Image/Logo?w=80&h=80)')})])])],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }