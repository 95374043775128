<template>
  <div>
    <!-- Breadcrumb -->
    <app-breadcrumb :items="breadcrumbs" :key="breadcrumbUpdated">
    </app-breadcrumb>
    <span class="closeMap" name="closeMap" style="" v-on:click="closeMap()">X</span>

    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-skeleton-img no-aspect height="250px"></b-skeleton-img>
        <b-skeleton class="mt-2 ml-auto mr-auto" animation="wave" height="30px" width="150px"></b-skeleton>
        <b-skeleton class="mt-2 ml-auto mr-auto" animation="wave" height="30px" width="85px"></b-skeleton>
        <div class="skeleton-avatar-container ml-auto mr-auto">
          <b-skeleton class="skeleton-avatar" size="50px" type="avatar"></b-skeleton>
          <b-skeleton class="skeleton-avatar" size="50px" type="avatar"></b-skeleton>
        </div>
        <b-skeleton class="mt-4 mb-5 ml-auto mr-auto" animation="wave" height="30px" width="150px"></b-skeleton>
        <b-skeleton-img height="250px"></b-skeleton-img>
        <div class="skeleton-expand-map-container ml-auto mr-auto">
          <b-skeleton class="ml-auto mr-auto" size="50px" type="avatar"></b-skeleton>
          <b-skeleton class="mt-2 ml-auto mr-auto" animation="wave" height="30px" width="150px"></b-skeleton>
        </div>
      </template>
      <div class="view-container" style="position: relative">
        <div class="image-container">
          <div>
            <b-carousel id="carousel" :interval="0" controls background="#ababab" img-height="250px"
              style="text-shadow: 1px 1px 2px #333">
              <b-carousel-slide :img-src="listingImageUrl1" img-height="250"></b-carousel-slide>
              <b-carousel-slide :img-src="listingImageUrl2" img-height="250"></b-carousel-slide>
              <b-carousel-slide :img-src="listingImageUrl3" img-height="250"></b-carousel-slide>
              <b-carousel-slide :img-src="listingImageUrl4" img-height="250"></b-carousel-slide>
            </b-carousel>
          </div>
          <div id="listing-logo-container">
            <div :style="listingLogoStyle"></div>
          </div>
        </div>
        <br />
        <br />
        <h2>{{ listing.Name }}</h2>
        <h6>{{ listing.Category1 }}</h6>
        <br />
        <br />
        <div class="button-container">
          <template v-if="listing.Tel != '' && listing.Tel != undefined">
            <a class="button" :href="'tel:' + listing.Tel" style="color: #fff; text-decoration: none" target="_blank"
              :value="listing.Tel">
              <div>
                <font-awesome-icon class="fa-icon" icon="phone" />
              </div>
            </a>
          </template>
          <template v-if="listing.Web != '' && listing.Web != undefined">
            <a class="button" :href="listing.Web" style="color: #fff; text-decoration: none" target="_blank"
              :value="listing.Web">
              <div>
                <font-awesome-icon class="fa-icon" icon="globe" />
              </div>
            </a>
          </template>
        </div>
        <hr />
        <h4>{{ listing.StoreNr }}</h4>
        <br />
        <template v-if="mapType != 'Google'">
          <img class="listing-map" :src="mapUrl" />
        </template>
        <template v-if="mapType == 'Google'">
          <div id="googleMap" ref="googleMap" class="scale-out">
            <div id="map" ref="map"></div>
          </div>
        </template>
        <template v-if="mapType == 'Google' && this.googleMapLoaded !== false">
          <br />
          <a class="button expandMap" style="color: #fff; text-decoration: none" v-on:click="initGoogleMapNav()">
            <div>
              <font-awesome-icon class="fa-icon" icon="map-marker-alt" />
            </div>
          </a>
          <span class="expandMap">
            <b>NAVIGATE</b>
          </span>
          <hr />
        </template>
        <label v-if="this.googleMapLoaded === false && mapType != 'SVG'">We do not have a location for this store</label>
        <br />
        <!-- Map -->
        <template v-if="mapType == 'SVG'">
          <SvgPanZoom v-if="mapType == 'SVG'" style="
                                    width: 100%;
                                    height: 100%;
                                    display: none;
                                    position: absolute;
                                    top: 25px;
                                    background-color: #d9d9d9;
                                    z-index: 2;
                                  " :zoomEnabled="true" :controlIconsEnabled="true" :fit="false" :center="true"
            :panEnabled="true" :zoomScaleSensitivity="0.3" :refreshRate="144" @svgpanzoom="registerSvgPanZoom">
            <svg name="mapElement" ref="mapElement"></svg>
          </SvgPanZoom>
          <br />
          <br />
          <a class="button expandMap" style="color: #fff; text-decoration: none" v-on:click="initMap(listingID, true)">
            <div>
              <font-awesome-icon class="fa-icon" icon="arrows-alt" />
            </div>
          </a>
          <span class="expandMap">
            <b>EXPAND MAP</b>
          </span>
          <hr />
        </template>
        <h5 v-show="products != null">Products we stock include:</h5>
        <li class="products" v-for="product in products" :key="product">
          {{ product }}
        </li>

        <hr v-show="products != null" />
        <!-- <a
      class="button"
      :href="'tel:' + listing.Tel"
      style="color: #fff; text-decoration: none"
      target="_blank"
      :value="listing.Tel"
    >
      <div>
        <font-awesome-icon class="fa-icon" icon="pencil-alt"/>
      </div>
    </a>
    <span>
      <b>REQUEST CHANGE</b>
    </span>
    <hr> -->
        <!-- <div class="footer-menu">
      <div class="alpha" @click="showListings()">
        <font-awesome-icon class="fa-icon" icon="sort-alpha-down" size="lg" />
        <span>Info</span>
      </div>
      <div class="categories" @click="showCategories()">
        <font-awesome-icon class="fa-icon" icon="clone" size="lg" />
        <span>Map</span>
      </div>
    </div> -->
        <h4 v-show="showDeals" class="deals-header">DEALS</h4>

        <ul class="deal-list">
          <li v-for="deal in deals" :key="deal.ID">
            <router-link :to="{
              path: '/Deal?did=' + deal.ID + '&sid=' + siteID,
            }">
              <div class="deal-banner-container" :style="
                'background-image: url(' +
                apiUrl +
                '/Sites' +
                '/Listings' +
                '/Deals/' +
                deal.ID +
                '/Image/Ad)'
              "></div>
              <div class="deal-info-container">
                <span class="deal-heading out-of-stock" v-if="deal.StockRemaining == 0 && deal.StockCount != 0">Out of
                  Stock</span>
                <span class="deal-heading">{{ deal.Name }}</span>
                <br />
                <span class="deal-text" v-if="deal.DealText">
                  {{ deal.DealText }}
                </span>
                <br v-if="deal.DealText" />
                <div class="price">
                  <span class="deal-text">
                    <strike>
                      R{{
                        (deal.RetailPrice != undefined
                          ? deal.RetailPrice
                          : 0
                        ).toFixed(2)
                      }}
                    </strike>
                  </span>
                  &nbsp;
                  <span class="deal-subheading">
                    R{{
                      (deal.DealPrice != undefined ? deal.DealPrice : 0).toFixed(
                        2
                      )
                    }}
                  </span>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </b-skeleton-wrapper>
  </div>
</template>
<!-- Scripts -->
<script>
import BreadcrumbPartial from "@/components/BreadcrumbPartial.vue";
// SVG Map
import SvgPanZoom from "vue-svg-pan-zoom";

const axios = require("axios");

const youAreHereGraphic = require("../assets/graphic-you-are-here.png");

export default {
  name: "Listing",
  components: {
    "app-breadcrumb": BreadcrumbPartial,
    SvgPanZoom,
  },
  data() {
    return {
      site: {},
      siteID: parseInt(this.$route.query.sid),
      listingID: parseInt(this.$route.query.id),
      listing: {},
      navItems: [],
      breadcrumbs: [],
      breadcrumbUpdated: false,
      listingImageUrl1: "",
      listingImageUrl2: "",
      listingImageUrl3: "",
      listingImageUrl4: "",
      listingLogoStyle: "",
      listingMapUrl: "",
      products: [],
      svgMapElement: null,
      mapUrl: "",
      deals: [],
      showDeals: false,
      googleMapLoaded: false,
      googleMap: null,
      googleMapID: "",
      mapMarkers: [],
      mapBounds: null,
      mapType: "",
      apiUrl: "",
      loading: false
    };
  },
  async created() {
    this.apiUrl = this.$contentApiUrl;
    this.loading = true;

    // Get the site's menu buttons
    await this.getSiteMenuButtons();

    // Get the site's groups
    await this.getSiteGroups();

    // Get the site's info
    await this.getSiteInfo();

    if (this.listing.Web !== "" && this.listing.Web !== undefined)
      this.listing.Web = this.listing.Web.startsWith("http")
        ? this.listing.Web
        : "http://" + this.listing.Web;

    // Get the listings map
    await this.getListingsMap();

    this.loading = false;
  },
  methods: {
    async getSiteMenuButtons() {
      var getSiteMenuUrl = this.$contentApiUrl + "/Sites/" + this.siteID + "/Navigation";
      var response = await axios.get(getSiteMenuUrl);

      this.navItems = response.data.Result;
    },
    async getSiteGroups() {
      var getSiteListingUrl = this.$contentApiUrl + "/Sites/" + "/Listings" + "?id=" + this.listingID;
      var response = await axios.get(getSiteListingUrl);

      this.listing = response.data.Result;
      if (this.listing.Products) {
        this.products = this.listing.Products.split(";");
        this.products = this.products.filter(function (el) {
          return el;
        });
      } else {
        this.products = null;
      }
    },
    async getSiteInfo() {
      var getSiteUrl = this.$contentApiUrl + "/Sites?id=" + this.siteID;
      var response = await axios.get(getSiteUrl);

      this.site = response.data.Result;
      this.breadcrumbs = ["Site", this.site.Name, this.listing.Name];
      this.breadcrumbUpdated = !this.breadcrumbUpdated;

      this.mapType = this.site.KioskSettings.find(
        (setting) => setting.Name == "MapProvider"
      ).Value;
      this.googleMapID = this.site.KioskSettings.find(
        (setting) => setting.Name == "GoogleMapsMapID"
      ).Value;

      var getMapUrl =
        this.$contentApiUrl +
        "/Sites/" +
        this.siteID +
        "/Listings/" +
        this.listingID +
        "/Maps/";

      if (this.mapType == "Google") {
        //We have to set a timeout to give the renderer a chance to load the GoogleMaps div first
        //If we do not move this to the back of the queue, we'll try to load data into a non existant div
        setTimeout(this.initGoogleMap, 5);
      } else if (this.orientationID) {
        let mapOrientation = this.getOrientationById(this.orientationID);
        getMapUrl += mapOrientation + "?";
        this.appendMap(getMapUrl, false, true);
      } else {
        var getSiteOrientationsUrl =
          this.$contentApiUrl +
          "/Sites/" +
          this.siteID +
          "/Maps/Orientations";
        // Else if we don't have the orientation, get the site's default orientation, append to URL and get the map in that orientation
        var siteOrientationResponse = await axios.get(getSiteOrientationsUrl);
        var siteMapData = siteOrientationResponse.data.Result;
        let mapOrientation = this.getOrientation(siteMapData[0].Name);
        getMapUrl += mapOrientation + "?";
        this.appendMap(getMapUrl, false, false);
      }
    },
    async getListingsMap() {
      var getSiteListingMapUrl = this.$contentApiUrl + "/Sites/" + "/Listings/" + this.listingID + "/Maps";
      var response = await axios.get(getSiteListingMapUrl);
      var orientation = response.data.Result.CardinalKey;

      this.listingMapUrl =
        this.$contentApiUrl +
        "/Sites/" +
        this.siteID +
        "/Listings/" +
        this.listingID +
        "/Maps/" +
        orientation +
        "?w=640&h=480";

      this.getListingImages(this);

      var dealsResponse = await axios
        .get(
          this.$contentApiUrl +
          "/Sites" +
          "/Listings/" +
          this.listingID +
          "/Deals"
        );

      this.deals = dealsResponse.data.Result;
      if (this.deals != null && this.deals.length > 0) this.showDeals = true;
    },
    getListingImages: function (_self) {
      _self.listingImageUrl1 =
        this.$contentApiUrl +
        "/Sites/Listings/" +
        _self.listingID +
        "/Image/101";
      _self.listingImageUrl2 =
        this.$contentApiUrl +
        "/Sites/Listings/" +
        this.listingID +
        "/Image/102";
      _self.listingImageUrl3 =
        this.$contentApiUrl +
        "/Sites/Listings/" +
        this.listingID +
        "/Image/103";
      _self.listingImageUrl4 =
        this.$contentApiUrl +
        "/Sites/Listings/" +
        this.listingID +
        "/Image/104";

      _self.listingLogoStyle =
        "background-image: url(" +
        this.$contentApiUrl +
        "/Sites/Listings/" +
        _self.listingID +
        "/Image/100?w=80&h=80)";
    },
    initGoogleMap() {
      if (this.googleMapLoaded || this.$refs["map"] == undefined) return;

      var latlong = new window.google.maps.LatLng(
        this.site.GpsLat,
        this.site.GpsLng
      );

      //Set map options
      var mapOptions = {
        center: { lat: latlong.lat(), lng: latlong.lng() },
        zoom: 15,
        tilt: 0,
        heading: 0,
        mapId: this.googleMapID, // This needs to be fetched from the DB
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: true,
      };

      var label = {
        text: "YOU ARE HERE",
        fontSize: "18px",
        color: "#0099cc",
        className: "youAreHereText",
      };

      var icon = {
        url: youAreHereGraphic,
        scaledSize: new window.google.maps.Size(50, 50),
        labelOrigin: new window.google.maps.Point(25, -15),
      };

      //Create marker
      var marker = new window.google.maps.Marker({
        position: latlong,
        animation: window.google.maps.Animation.DROP,
        icon: icon,
        label: label,
      });
      this.mapMarkers.push(marker);

      //Set bounds to include marker position
      this.mapBounds = new window.google.maps.LatLngBounds();
      this.mapBounds.extend(marker.position);

      //Load map
      this.googleMap = new window.google.maps.Map(
        this.$refs["map"],
        mapOptions
      );

      //Place Marker
      marker.setMap(this.googleMap);

      if (this.listing !== undefined)
        if (this.loadGoogleMapListing()) this.googleMapLoaded = true;
        else this.$refs["googleMap"].remove();
    },
    loadGoogleMapListing() {
      if (this.listing.GeoLat === undefined) return false;
      // Load the listing's location
      // Add listing plot marker on map
      // Create marker
      var marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(
          this.listing.GeoLat,
          this.listing.GeoLng
        ),
        animation: window.google.maps.Animation.DROP,
      });
      this.mapMarkers.push(marker);

      // Place Marker
      marker.setMap(this.googleMap);

      // Extend map bounds
      this.mapBounds.extend(marker.position);

      // Fit the map to bounds
      this.googleMap.fitBounds(this.mapBounds);
      return true;
    },
    registerSvgPanZoom(svgpanzoom) {
      this.svgMapElement = svgpanzoom;
    },
    initMap: function (listingID) {
      // Hide and show containers

      document.getElementsByName("mapElement")[0].parentNode.style.display =
        "block";
      document.getElementsByName("closeMap")[0].style.display = "block";
      document.getElementsByClassName("listing-map")[0].style.display = "none";
      var el = document.getElementsByClassName("expandMap");
      for (var i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }

      var getMapUrl =
        this.$contentApiUrl +
        "/Sites/" +
        this.siteID +
        "/Listings/" +
        listingID +
        "/Maps/";

      if (this.orientationID) {
        var mapOrientation = this.getOrientationById(this.orientationID);
        getMapUrl += mapOrientation + "?";
        this.appendMap(getMapUrl, false, true);
      } else {
        var getSiteOrientationsUrl =
          this.$contentApiUrl + "/Sites/" + this.siteID + "/Maps/Orientations";
        // Else if we don't have the orientation, get the site's default orientation, append to URL and get the map in that orientation
        axios.get(getSiteOrientationsUrl).then((response) => {
          var siteMapData = response.data.Result;
          var mapOrientation = this.getOrientation(siteMapData[0].Name);
          getMapUrl += mapOrientation + "?";
          this.appendMap(getMapUrl, false, true);
        });
      }
      window.scrollTo(0, 0);
    },
    initGoogleMapNav: function () {
      var listingLatlng = this.listing.GeoLat + " " + this.listing.GeoLng;
      var siteLatlng = this.site.GpsLat + " " + this.site.GpsLng;
      window.open(
        "https://www.google.com/maps/dir/?api=1&origin=" +
        siteLatlng +
        "&destination=" +
        listingLatlng
      );
    },
    appendMap: function (getMapUrl, showPlots = false, svgFormat = false) {
      var _self = this;

      if (this.kioskID) getMapUrl += "kid=" + this.kioskID + "&";

      getMapUrl += "w=640&h=480";

      if (svgFormat) getMapUrl += "&imageType=SVG";

      if (showPlots) getMapUrl += "&showGrid=true";

      if (svgFormat) {
        axios.get(getMapUrl).then((response) => {
          if (svgFormat) {
            var parser = new DOMParser();
            console.log(response);
            var xmlDoc = parser.parseFromString(response.data, "text/xml");
            console.log(xmlDoc);
            var mapSvg = "";
            var mapNodes = xmlDoc.getElementsByTagName("svg")[0].childNodes;
            for (var i = 0; i < mapNodes.length; i++) {
              mapSvg += mapNodes[i].outerHTML;
            }

            _self.$refs["mapElement"].childNodes[0].innerHTML = mapSvg;
          }
        });
      } else {
        this.mapUrl = getMapUrl;
      }
    },
    getOrientationById: function (orientationId) {
      var abbreviation;
      switch (orientationId) {
        case 1:
          abbreviation = "N";
          break;
        case 2:
          abbreviation = "NE";
          break;
        case 3:
          abbreviation = "E";
          break;
        case 4:
          abbreviation = "SE";
          break;
        case 5:
          abbreviation = "S";
          break;
        case 6:
          abbreviation = "SW";
          break;
        case 7:
          abbreviation = "W";
          break;
        case 8:
          abbreviation = "NW";
          break;
      }

      return abbreviation;
    },
    getOrientation: function (orientation) {
      var abbreviation;
      switch (orientation) {
        case "North":
          abbreviation = "N";
          break;
        case "North East":
          abbreviation = "NE";
          break;
        case "East":
          abbreviation = "E";
          break;
        case "South East":
          abbreviation = "SE";
          break;
        case "South":
          abbreviation = "S";
          break;
        case "South West":
          abbreviation = "SW";
          break;
        case "West":
          abbreviation = "W";
          break;
        case "North West":
          abbreviation = "NW";
          break;
      }

      return abbreviation;
    },
    closeMap: function () {
      // Hide and show containers
      document.getElementsByName("mapElement")[0].parentNode.style.display =
        "none";
      document.getElementsByName("closeMap")[0].style.display = "none";
      document.getElementsByClassName("listing-map")[0].style.display = "block";
      var el = document.getElementsByClassName("expandMap");
      for (var i = 0; i < el.length; i++) {
        el[i].style.display = "block";
      }
    },
  },
};
</script>
<!-- Styles -->
<style scoped>
.view-container {
  text-align: center;
  background-color: #d9d9d9 !important;
  padding-bottom: 15px;
}

.view-container a.button {
  color: black !important;
}

.view-container a.button>div {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #7b7b7b;
  border-radius: 50%;
  height: 53px;
  width: 53px;
}

.skeleton-avatar-container {
  margin-top: 80px;
  display: flex;
  place-content: center;
}
.skeleton-avatar {
  margin: 10px;
}
.skeleton-expand-map-container {
  padding-bottom: 50px;
  margin-top: 65px;
}

.image-container {
  position: relative;
}

#listing-logo-container {
  position: absolute;
  bottom: -40px;
  left: 50%;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0px auto;
}

#listing-logo-container>div {
  position: relative;
  left: -50%;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0px auto;
}

.listing-map {
  width: 100%;
}

li.products {
  list-style-type: none;
  background-color: #565656;
  color: white;
  border-radius: 4px;
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  margin: 2px;
}

div.footer-menu {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 55px;
  display: flex;
  text-align: center;
  background-color: #565656;
  color: white;
}

div.footer-menu>div {
  flex: 1;
  margin: auto;
}

div.footer-menu>div span {
  display: block;
  font-size: 11px;
}

div.button-container {
  display: inline-flex;
  padding: 5px;
  place-content: center;
}

div.button-container a {
  margin: 5px;
}

svg[name="mapElement"] {
  margin: auto;
  height: 100%;
  border: solid 1px #c3c3c3;
  border-radius: 3px;
  box-shadow: 0px 0px 50px 2px #c3c3c3;
  position: absolute;
  left: 0px;
  width: 100%;
}

/* Deals */
.deals-header {
  margin-bottom: 17px;
}

.deal-banner-container {
  min-width: 80px;
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.deal-info-container {
  display: inline-block;
  top: 10px;
  right: 10px;
  width: 100%;
  padding-left: 10px;
  text-align: left;
}

.deal-list {
  list-style: none;
  padding: 0px;
}

.deal-list li {
  width: 100%;
  padding: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #d9d9d9;
}

.deal-list li a {
  display: flex;
}

span.deal-heading {
  color: #4c4c4c;
  font-size: 17px;
  font-weight: 500;
}

span.deal-text {
  color: #a0a0a0;
  font-size: 14px;
  font-weight: 500;
}

span.deal-subheading {
  color: #4c4c4c;
  font-size: 18px;
  font-weight: 500;
}

span.out-of-stock {
  position: absolute;
  top: 15px;
  right: 15px;
  border: solid 1px #4c4c4c;
  padding-left: 5px;
  padding-right: 5px;
  background-color: white;
}

.closeMap {
  display: none;
  position: relative;
  z-index: 3;
  background-color: rgb(161 156 156);
  float: right;
  top: 30px;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  right: 5px;
}

/*Disable google map buttons*/
#googleMap div.gmnoprint a,
#googleMap div.gmnoprint button[title="Keyboard shortcuts"] {
  pointer-events: none !important;
  display: none !important;
}

#googleMap #map a {
  display: block !important;
  pointer-events: none !important;
}

/*Hide Info Windows*/
#googleMap div.gm-style .gm-style-iw-c {
  display: none !important;
}

#googleMap div.gm-style .gm-style-iw-t::after {
  background: none !important;
}

/*Styling*/
#googleMap {
  position: relative;
  pointer-events: none;
}

#googleMap.scale-out {
  height: 400px;
  width: 90%;
  left: 18px;
  bottom: 0px;
}

#googleMap.scale-in {
  height: 860px;
  width: 1440px;
  left: -360px;
  bottom: 190px;
}

#googleMap.expand {
  animation: expand 0.85s linear;
}

#googleMap.retract {
  animation: retract 0.85s linear;
}

#googleMap #map {
  height: 100%;
  pointer-events: auto;
}

#googleMap .map-buttons-container {
  bottom: 1040px;
  position: sticky;
  pointer-events: auto;
  width: 6%;
  float: right;
}

#googleMap button.map-buttons {
  left: -10%;
  position: relative;
}

#googleMap button.map-buttons span {
  font-weight: bold;
  text-shadow: -0.5px -0.5px 0 #fff, 0.5px -0.5px 0 #fff, -0.5px 0.5px 0 #fff,
    0.5px 0.5px 0 #fff;
}

#googleMap .map-instruction-container {
  width: 100%;
  position: absolute;
  bottom: 100px;
}

#googleMap button.map-buttons:active {
  position: relative;
  animation-name: button-appearing;
  animation-delay: 0s;
  animation-duration: 350ms;
  animation-fill-mode: both;
}

#googleMap .youAreHereText {
  font-weight: bold;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;
}
</style>
