<template>
    <b-navbar id="main-nav-bar" toggleable="lg" type="dark" variant="info">
        <div class="navbar-menu">
            <b-button v-show="showBack" v-on:click="back()">
                <font-awesome-icon icon="arrow-left" />
            </b-button>
            <!-- <b-button v-b-toggle.sidebar-backdrop>
                <font-awesome-icon icon="map-marker" />
            </b-button> -->
        </div>
        <div class="navbar-home">
            <router-link to="/">
                <img src="@/assets/logos/ipoint-logo-85x40.png" />
            </router-link>
        </div>
        <div class="navbar-buttons">
            <router-link :to="'/site?id=' + siteID" v-show="showGroups">
                <b-button>
                    <font-awesome-icon icon="home" />
                </b-button>
            </router-link>
            <b-dropdown v-if="isLogedIn" class="logout-button" no-caret id="dropdown-right" right text="Right align">
                <template #button-content>
                    <font-awesome-icon icon="user" />
                </template>
                <b-dropdown-item @click="logout">Logout</b-dropdown-item>
            </b-dropdown>
        </div>
    </b-navbar>
</template>
<script>
import { appLocalStorage } from "@/mixins/appLocalStorage";

export default {
    mixins: [appLocalStorage],
    watch: {
        $route() {
            this.updateRoute();
            this.checkLoginStatus();
        }
    },
    methods: {
        updateRoute: function () {
            if (this.$route.name == 'Categories' || this.$route.name == 'Listings' || this.$route.name == 'Listing' || this.$route.name == 'Deals') {
                this.showGroups = true;
            }
            else {
                this.showGroups = false;
            }

            if (this.$route.name != 'Proximity' && window.history.length > 1) {
                this.showBack = true;
            }
            else {
                this.showBack = false;
            }

            if (this.$route.query.sid)
                this.siteID = this.$route.query.sid;
        },
        checkLoginStatus() {
            this.isLogedIn = this.GetToken() ? true : false 
        },
        back: function () {
            window.history.back();
        },
        logout() {
            this.$emit('logout');
        }
    },
    data() {
        return {
            isLogedIn: false,
            showGroups: false,
            showBack: false,
            siteID: this.$route.query.sid,
        };
    },
    created() {
        this.updateRoute();
        this.checkLoginStatus();
    }
};
</script>
<!-- Styles -->
<style scoped>
#main-nav-bar {
    background-color: #444444 !important;
    z-index: 4;
}

#main-nav-bar>div {
    width: 33%;
    text-align: center;
}

#main-nav-bar>div:first-child {
    text-align: left;
}

#main-nav-bar>div:last-child {
    text-align: right;
}

.logout-button {
    margin-left: 15px;
}
</style>.