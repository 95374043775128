<template>
  <div>
    <loginMain></loginMain>
    <span style="display: block; text-align: center" v-if="deals.length == 0">
      <br />
      There are no deals available at this time.
    </span>
    <ul class="deal-list">
      <li v-for="deal in deals" :key="deal.ID">
        <router-link :to="{
            path: '/Deal?did=' + deal.ID + '&sid=' + siteID,
          }">
          <div class="deal-banner-container" :style="'background-image: url(' +
            apiUrl +
            '/Sites' +
            '/Listings' +
            '/Deals/' +
            deal.ID +
            '/Image/Ad)'
            "></div>
          <hr />
          <span class="deal-heading out-of-stock" v-if="deal.StockRemaining == 0 && deal.StockCount != 0">Out of
            Stock</span>
          <span class="deal-heading">{{ deal.Name }}</span>
          <br />
          <span class="deal-text" v-if="deal.DealText">{{
            deal.DealText
          }}</span>
          <br v-if="deal.DealText" />
          <div class="price">
            <span class="deal-text"><strike>R{{
              (deal.RetailPrice != undefined
                ? deal.RetailPrice
                : 0
              ).toFixed(2)
            }}</strike></span>
            &nbsp;
            <span class="deal-subheading">R{{
              (deal.DealPrice != undefined ? deal.DealPrice : 0).toFixed(2)
            }}</span>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<!-- Scripts -->
<script>
const axios = require("axios");
import loginMain from "@/components/login/loginMain";
import { appLocalStorage } from "@/mixins/appLocalStorage";
export default {
  name: "Sites",
  components: {
    loginMain: loginMain
  },
  data() {
    return {
      apiUrl: this.$contentApiUrl,
      siteID: this.$route.query.sid,
      deals: [],
    };
  },
  mixins: [appLocalStorage],
  created() {
    axios
      .get(
        this.$contentApiUrl +
        "/Sites/" +
        this.siteID +
        "/Listings" +
        "/Deals"
      )
      .then((response) => {
        var data = response.data.Result;
        var filteredDeals = [];
        for (var f = 0; f < data.length; f++) {
          var contains = false;

          for (var fd = 0; fd < filteredDeals.length; fd++)
            if (filteredDeals[fd].ID == data[f].ID) contains = true;

          if (!contains) filteredDeals.push(data[f]);
        }
        this.deals = filteredDeals;
      });
  },
  mounted() {
    // Removed for now as we do not need logins here
    // TODO: For this to be re-implemented we need to be able to take users straight to deal redemption
    // if (!this.GetToken())
    //   this.$bvModal.show("loginModal");
  }
};
</script>
<!-- Styles -->
<style scoped>
.deal-banner-container {
  width: 100%;
  height: 220px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 20px;
}

.deal-list {
  list-style: none;
  padding: 0px;
}

.deal-list li {
  position: relative;
  margin-bottom: 5px;
  padding: 10px;
  padding-top: 0px;
  padding-bottom: 5px;
  border-bottom: 1px solid black;
}

span.deal-heading {
  color: #4c4c4c;
  font-size: 20px;
  font-weight: 600;
}

span.deal-text {
  color: #a0a0a0;
  font-size: 14px;
  font-weight: 500;
}

span.deal-subheading {
  color: #4c4c4c;
  font-size: 18px;
  font-weight: 500;
}

div.price {
  text-align: right;
}

span.out-of-stock {
  position: absolute;
  top: 15px;
  right: 15px;
  border: solid 1px #4c4c4c;
  padding-left: 5px;
  padding-right: 5px;
  background-color: white;
}
</style>
