import { appLocalStorage } from "@/mixins/appLocalStorage";

export const splashScreen = {
    data() {
        return {
            showLoader: false,
            fadeoutLoader: false
        }
    },
    mixins: [appLocalStorage],
    methods: {
        initSplashScreen: function () {
            this.showLoader = this.GetShowLoader();
            this.fadeoutLoader = this.GetFadeoutLoader();

            if (this.showLoader) {
                this.SetShowLoader(false);
                setTimeout(() => {
                    this.fadeoutLoader = true;
                    this.SetFadeoutLoader(true);
                }, 5000);
            }
        }
    }
}