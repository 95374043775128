<template>
  <div id="banner-container">
    <div id="search-container" :class="$route.path === '/Search' ? 'search-page' : ''" :style="bannerImageStyle">
      <div id="search-box">
        <span id="span-looking-for">FIND WHAT YOU ARE LOOKING FOR...</span>
        <input id="search" type="text" class="form-control" v-model=inputSearchText
          :placeholder="'Search | ' + this.locationString" v-on:keyup="search()" />
      </div>
    </div>
  </div>
</template>
<!-- Scripts -->
<script>
import { appLocalStorage } from "@/mixins/appLocalStorage";

export default {
  name: "Banner",
  props: {
    siteID: Number,
    subRegionID: Number,
    location: String,
    searchText: String
  },
  data() {
    return {
      bannerImageStyle: !isNaN(this.siteID)
        ? "background-image: url(" +
        this.$contentApiUrl +
        "/Sites/" +
        this.siteID +
        "/Image/Banner)"
        : !isNaN(this.subRegionID)
          ? "background-image: url(" +
          this.$contentApiUrl +
          "/Countries/Regions/SubRegions/" +
          this.subRegionID +
          "/Image/Banner)"
          : "background-image: url(" +
          this.$contentApiUrl +
          "/Image/Banner)",
      locationString: "South Africa",
      inputSearchText: this.searchText
    };
  },
  created() {
    this.locationString = this.location == undefined ? this.locationString : this.location;
  },
  mixins: [appLocalStorage],
  methods: {
    search() {
      var _self = this;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      this.timer = setTimeout(() => {
        if (this.inputSearchText && this.inputSearchText.trim().length !== 1) {
          var searchParam = "q=" + encodeURIComponent(this.inputSearchText.trim());
          var searchQuery = "Search?" + searchParam;
          var geolocation = "";

          if (window.location.pathname.toLowerCase() == "/site")
            searchQuery += "&sid=" + this.$route.query.id;

          if (this.$route.query.sid != undefined)
            searchQuery += "&sid=" + this.$route.query.sid;

          if (this.HasGeolocation()) {
            geolocation = this.GetGeolocation();
            searchQuery +=
              "&lat=" +
              geolocation.lat +
              "&lng=" +
              geolocation.lng +
              "&radiusKm=" +
              75;
          }
          // else if (this.HasLocation()) {
          //   var location = this.GetLocation();
          //   searchQuery +=
          //     "&cid=" +
          //     location.cid +
          //     "&rid=" +
          //     location.rid +
          //     "&srid=" +
          //     location.srid +
          //     "&radiusKm=" +
          //     75;
          // }

          if (window.location.href != window.location.origin + "/" + searchQuery && this.inputSearchText.trim() != "") {
            _self.$router.push(searchQuery);
            _self.$router.go();
          } else if (this.inputSearchText.trim() === '') {
            var returnUrl = "/";

            if (_self.$route.query.sid !== undefined)
              returnUrl += "site?id=" + _self.$route.query.sid;

            _self.$router.push(returnUrl);
            _self.$router.go();
          }
        }        
      }, 1125);
    },
  },
};
</script>
<!-- Styles -->
<style scoped>
#search-container {
  width: 100%;
  height: 130px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  opacity: 0.8;
  padding-top: 19px;
}

#search-container.search-page {
  padding-top: 38px;
}

#search-box {
  width: 80%;
  background-color: rgb(0, 0, 0, 0.3);
  margin: 0 auto;
  padding: 10px;
  padding-top: 3px;
  text-align: center;
}

#span-looking-for {
  font-size: 12px;
  margin-bottom: 2px;
  display: block;
  color: #fff;
}
</style>