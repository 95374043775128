<template>
    <div>
        <app-breadcrumb v-bind:items="breadcrumbs" :key="breadcrumbUpdated"></app-breadcrumb>
        <div class="view-container">
            <img class="center" :src="imageUrl">
            <h2 class="center" id="title">{{ title }}</h2>
            <div class="center" style="display: flex;">
                <h6 @click="toggleInfo()">Information |</h6>
                <h6 @click="toggleTsAndCs()">| Ts & Cs</h6>
            </div>
            <b-collapse class="center text" id="description" v-model="showInfo">{{ description }}</b-collapse>
            <b-collapse class="left text" id="tsandcs" v-model="showTsAndCs">{{ tsandcs }}</b-collapse>
            <div v-if="!showEntries">
                <button class="center shimmer action-btn" id="entries-btn" @click="enter">Enter</button>
            </div>
            <div v-else>
                <h3 class="center">You have {{ totalEntries }} entries</h3>
                <h3 class="center">{{ parsedCompetitionEndDate }} day's remaining</h3>
                <button v-if="freeDailyEntry && freeDailyEntry.Claimed == false" class="center shimmer action-btn"
                    id="claim-btn" @click="claimFreeEntry">Free Entry</button>
                <h6 class="center mb-3" v-else>Come back tomorrow for another free entry</h6>
            </div>
        </div>
    </div>
</template>

<script>
import BreadcrumbPartial from "@/components/BreadcrumbPartial.vue";
import { appLocalStorage } from "@/mixins/appLocalStorage";
import Axios from "axios";

export default {
    name: "Competiition",
    components: {
        "app-breadcrumb": BreadcrumbPartial
    },
    mixins: [appLocalStorage],
    data() {
        return {
            competitionID: parseInt(this.$route.query.id),
            breadcrumbs: [],
            breadcrumbUpdated: false,
            imageUrl: "",
            title: "",
            description: "",
            tsandcs: "",
            showTsAndCs: false,
            showInfo: true,
            showEntries: false,
            competitionEndDate: null,
            totalEntries: 0,
            availableCompetitionEntries: []
        };
    },
    computed: {
        parsedCompetitionEndDate: function () {
            if (!this.competitionEndDate)
                return '0';

            const currentDate = new Date();
            // Calculate the time difference in milliseconds
            const timeDiff = Math.abs(this.competitionEndDate.getTime() - currentDate.getTime());
            // Calculate the number of days
            const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

            return daysDiff;
        },
        freeDailyEntry: function () {
            if (!this.availableCompetitionEntries || this.availableCompetitionEntries.length == 0)
                return null;

            return this.availableCompetitionEntries[0];
        }
    },
    async created() {
        this.breadcrumbs = ["Competition"];
        this.breadcrumbUpdated = !this.breadcrumbUpdated;

        if (this.GetToken()) // Loged in
            this.getCompetitionEntires();
        else // Not logged in 
            this.getCompetition();
    },
    methods: {
        async getCompetition() {
            var _self = this;
            this.showEntries = false;
            var url = this.$contentApiUrl + "/Competition/" + this.competitionID;
            await Axios.get(url).then(function (response) {
                var res = response.data.Result;

                _self.title = res.Name;
                _self.description = res.Description;
                _self.tsandcs = res.TsAndCs;
                _self.imageUrl = _self.$contentApiUrl + "/Competitions/" + _self.competitionID + "/Image";
            });
        },
        async getCompetitionEntires() {
            var _self = this;
            this.showEntries = true;
            var url = this.$contentApiUrl + "/CompetitionEntries/" + this.competitionID + '?token=' + this.GetToken();
            await Axios.get(url).then(function (response) {
                var res = response.data.Result;

                _self.title = res.Competition.Name;
                _self.description = res.Competition.Description;
                _self.tsandcs = res.Competition.TsAndCs;
                _self.imageUrl = _self.$contentApiUrl + "/Competitions/" + _self.competitionID + "/Image";
                _self.competitionEndDate = new Date(res.Competition.EndDate);
                _self.totalEntries = res.TotalEntries;
                _self.availableCompetitionEntries = res.AvailableCompetitionEntries;
            });
        },
        toggleTsAndCs() {
            this.showTsAndCs = true;
            this.showInfo = false;
        },
        toggleInfo() {
            this.showInfo = true;
            this.showTsAndCs = false;
        },
        enter() {
            // Show login modal
            this.$root.$emit('showLoginModal', '/Competition?id=' + this.competitionID)
        },
        async claimFreeEntry() {
            var _self = this;
            var url = this.$contentApiUrl + "/Competition/" + this.competitionID + "/Entries/" + this.GetToken() + "/AddCompetitionEntry/" + this.freeDailyEntry.CompetitionPointTypesID;
            await Axios.get(url).then(function () {
                _self.getCompetitionEntires();
            });
        },
    },
};
</script>

<style>
#title {
    margin-bottom: 20px;
}

#tsandcs {
    color: grey;
}

.action-btn {
    width: 100px;
    height: 50px;
    border-radius: 10%;
    background-color: #ffe875;
    color: rgb(0 0 0 / 68%);
    border: none;
    box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 63%);
    font-weight: bold;
    font-size: smaller;
    position: sticky;
    left: 50%;
    bottom: 0px;
    transform: translate(-50%, -50%);
}

#claim-btn {
    position: relative;
    margin-top: 40px;
    font-size: medium;
}

.center {
    margin-top: 20px;
}

.text {
    white-space: pre-line;
    white-space: pre-wrap;
    padding: 10px;
    margin: 10px;
}

.bordered {
    border-width: 2px;
    /* Adjust the width as needed */
    border-style: solid;
    border-image: linear-gradient(to bottom, #ffff, #369ed5, #369ed5, #369ed5, #ffff) 1 100%;
}

.shimmer {
    position: relative;
    overflow: hidden;
}

.shimmer::after {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(to right, transparent 0%, rgb(255 250 221 / 72%) 50%, transparent 100%);
    animation: shimmerAnimation 2s infinite;
}

@keyframes shimmerAnimation {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}
</style>