export const appLocalStorage = {
    methods: {
        // Functions to show/hide the splash screen
        SetShowLoader: function (value) {
            return localStorage.showLoader = value;
        },
        GetShowLoader: function () {
            return typeof localStorage.showLoader === 'undefined'
                ? true
                : localStorage.showLoader === 'true';
        },
        // Functions to apply the 'fadeout' animation on the splash screen
        SetFadeoutLoader: function (value) {
            return localStorage.fadeoutLoader = value;
        },
        GetFadeoutLoader: function () {
            return typeof localStorage.fadeoutLoader === 'undefined'
                ? false
                : localStorage.fadeoutLoader === 'true';
        },
        // Functions to keep track of the user's location
        HasGeolocation: function () {
            return localStorage.geolocation != '' && localStorage.geolocation != undefined;
        },
        SetGeolocation: function (lat, lng) {
            var obj = { lat: lat, lng: lng };
            return localStorage.geolocation = JSON.stringify(obj);
        },
        GetGeolocation: function () {
            return localStorage.geolocation == '' && localStorage.geolocation != undefined
                ? {}
                : JSON.parse(localStorage.geolocation);
        },
        ClearGeolocation: function () {
            localStorage.geolocation = '';
        },
        // Functions to keep track of the user's location
        HasLocation: function () {
            return localStorage.location != '' && localStorage.location != undefined;
        },
        SetLocation: function (cid, rid, srid) {
            var obj = { cid: cid, rid: rid, srid: srid };
            return localStorage.location = JSON.stringify(obj);
        },
        GetLocation: function () {
            return localStorage.location == '' && localStorage.location != undefined
                ? {}
                : JSON.parse(localStorage.location);
        },
        ClearLocation: function () {
            localStorage.location = '';
        },
        SetToken: function (token) {
            return localStorage.token = token;
        },
        RemoveToken: function() {
            localStorage.removeItem('token');
        },
        GetToken: function () {
            return localStorage.token;
        },
    }
}