<template>
  <form class="p-5" autocomplete="off" v-on:submit.prevent>
    <div class="input-group mb-3">
      <span class="input-group-text">
        <font-awesome-icon class="fa-icon" icon="envelope" />
      </span>
      <input v-model="email" type="text" autocomplete="new-password" class="form-control transparent-background text-light"
        placeholder="enter your e-mail" />
    </div>
    <div class="input-group mb-3">
      <span class="input-group-text">
        <font-awesome-icon class="fa-icon" icon="lock" />
      </span>
      <input v-model="password" type="password" autocomplete="new-password" class="form-control transparent-background text-light"
        placeholder="enter your password" />
    </div>
    <div class="text-center mb-3">
      <label class="login-error-text">
        {{ errorMessage }}
      </label>
    </div>
    <div class="text-center">
      <button type="submit" class="btn w-100 border-0 text-light login-button" @click="signIn">
        <strong>Login</strong>
      </button>
    </div>
  </form>
</template>
  
<script>
const axios = require("axios");
import { appLocalStorage } from "@/mixins/appLocalStorage";
export default {
  name: 'signIn',
  data() {
    return {
      email: null,
      password: null,
      errorMessage: ""
    }
  },
  mixins: [appLocalStorage],
  methods: {
    async signIn() {
      var url = this.$contentApiUrl + '/Account/LoginMobileUser';
      var model = {
        EmailAddress: this.email,
        Password: this.password,
      }
      const headers = {
        'Content-Type': 'application/json'
      }

      axios.post(url, JSON.stringify(model), { headers: headers })
        .then(response => {
          if (response.status == 200)
            if (response.data.Success == true) {
              this.SetToken(response.data.Result.AuthToken);
              this.$bvModal.hide("loginModal");
              this.$emit('logedIn');
            }
            else
              this.errorMessage = response.data.Result;
          else
            this.errorMessage = "Error";
        })
        .catch(error => {
          if (error.response)
            this.errorMessage = error.response.data.ExceptionMessage;
          else
            this.errorMessage = "Network error";
        })
    },
  },
}
</script>
<style>
.login-button {
  background: linear-gradient(90deg, rgba(6, 154, 231, 1) 0%, rgba(35, 204, 236, 1) 100%);
}

.forget-password {
  float: right;
  margin-top: -1px;
}

#rememberMe {
  margin-top: 0.5rem;
}
</style>