<template>
  <form class="signup-form" autocomplete="new-password" v-on:submit.prevent>
    <div class="input-group mb-3">
      <span class="input-group-text">
        <font-awesome-icon class="fa-icon" icon="envelope" />
      </span>
      <input v-model="email" type="text" autocomplete="new-password" class="form-control transparent-background text-light"
        placeholder="enter your e-mail" />
    </div>
    <div class="input-group mb-3">
      <span class="input-group-text">
        <font-awesome-icon class="fa-icon" icon="lock" />
      </span>
      <input v-model="password" type="password" autocomplete="new-password" class="form-control transparent-background text-light"
        placeholder="enter your password" />
    </div>
    <div class="input-group mb-3">
      <span class="input-group-text">
        <font-awesome-icon class="fa-icon" icon="lock" />
      </span>
      <input v-model="confirmPassword" type="password" autocomplete="new-password" class="form-control transparent-background text-light"
        placeholder="confirm your password" />
    </div>
    <div class="text-center mb-3">
      <label class="login-error-text">
        {{ errorMessage }}
      </label>
    </div>
    <div class="text-center">
      <button type="submit" class="btn w-100 border-0 text-light register-button" @click="signUp">
        <strong>Register</strong>
      </button>
    </div>
  </form>
</template>
  
<script>
const axios = require("axios");
export default {
  name: 'signUp',
  data() {
    return {
      email: null,
      password: null,
      confirmPassword: null,
      errorMessage: ""
    }
  },
  methods: {
    async signUp() {
      var url = this.$contentApiUrl + '/Account/CreateMobileUser';
      var model = {
        userName: this.email,
        emailAddress: this.email,
        Password: this.password,
        ConfirmPassword: this.confirmPassword,
        ReturnUrl: this.$subDomainUrl + this.$route.fullPath
      }
      const headers = {
        'Content-Type': 'application/json'
      }

      axios.post(url, JSON.stringify(model), { headers: headers })
        .then(response => {
          if (response.status == 200)
            if (response.data.Success == true)
              this.$emit('updateIsConformation');
            else
              this.errorMessage = response.data.Result;
          else
            this.errorMessage = "Error";
        })
        .catch(error => {
          if (error.response)
            this.errorMessage = error.response.data.ExceptionMessage;
          else
            this.errorMessage = "Network error";
        })
    },
  },
}
</script>
<style>
.signup-form {
  padding: 1rem 3rem 4.15rem 3rem !important;
}

.register-button {
  background: linear-gradient(90deg, rgba(6, 154, 231, 1) 0%, rgba(35, 204, 236, 1) 100%);
}
</style>