<template>
    <div>
        <div v-if="showLoader">
            <app-loader :fadeoutLoader="fadeoutLoader"></app-loader>
        </div>
        <div v-if="fadeoutLoader">
            <app-banner></app-banner>
            <div class="location" v-on:click="useLocation($event)">
                <div>
                    <font-awesome-icon icon="map-marker" />
                </div>
                <div>
                    <span>
                        <b>Detect my location</b>
                    </span>
                    <br />
                    <span>Show results closest to me.</span>
                </div>
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitches" v-model="locationEnabled" />
                    <label class="custom-control-label" for="customSwitches"></label>
                </div>
            </div>
            <b-skeleton-wrapper :loading="loading">
                <template #loading>
                    <ul name="sites">
                        <li class="menu-item" v-for="i in 10" :key="i">
                            <b-skeleton class="skeleton-label" animation="wave" height="50px" width="100%"></b-skeleton>
                            <b-skeleton-img v-if="locationEnabled" no-aspect height="100px"></b-skeleton-img>
                        </li>
                    </ul>
                </template>
                <div class="view-container" v-if="locationEnabled">
                    <div class="section-title">
                        <span>
                            Nearest Site:
                            <b>{{ nearestSite.Name }}</b>
                        </span>
                    </div>
                    <div v-for="site in orderedSites" :key="site.Name" class="section-title">
                        <span>{{ site.Name }} - {{ site.Distance }} Km</span>

                        <router-link v-if="site.BannerStyle != ''" :key="site.ID" :to="{ path: '/site?id=' + site.ID }">
                            <div id="site-banner-container" :style="site.BannerStyle">
                                <div id="site-logo-container" :style="site.LogoStyle"></div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <ul v-if="!locationEnabled" name="sites">
                    <li :class="'menu-item ' + site.Class" v-for="site in orderedSites" :key="site.ID">
                        <router-link v-if="site.Class == ''" :to="{ path: '/Site?id=' + site.ID }">
                            {{ site.Name }}
                        </router-link>
                        <span v-if="site.Class != ''">{{ site.Name }}</span>
                    </li>
                </ul>
            </b-skeleton-wrapper>
        </div>
    </div>
</template>
<script>
import { appLocalStorage } from "@/mixins/appLocalStorage";
import BannerPartial from "@/components/BannerPartial.vue";
import LoaderPartial from "@/components/LoaderPartial.vue";
import { splashScreen } from "@/mixins/splashScreen";

const axios = require("axios");

export default {
    name: "Proximity",
    components: {
        "app-banner": BannerPartial,
        "app-loader": LoaderPartial,
    },
    data() {
        return {
            sites: [],
            orderedSites: [],
            sitesAtoZ: [],

            regionBannerImageStyle:
                "background-image: url(" +
                this.$contentApiUrl +
                "/Image/Banner)",
            siteBannerStyle: "",
            siteLogoStyle: "",
            subRegionBannerStyle: "",
            locationEnabled: false,
            latitude: 0,
            longitude: 0,
            nearestSite: {},
            nearestSiteID: 0,
            location: {},
            country: 0,
            region: 0,
            subRegionID: 0,
            subRegionName: "",
            timer: null,
            loading: false
        };
    },
    mixins: [splashScreen, appLocalStorage],
    async mounted() {
        this.loading = true;
        this.initSplashScreen();

        if (this.HasGeolocation())
            await this.useLocation();
        else
            await this.getSites();

        this.loading = false;
    },
    methods: {
        async useLocation(event) {
            this.loading = true;
            this.orderedSites = [];
            this.sitesAtoZ = [];
            if (event !== undefined) {
                event.preventDefault();
                event.stopPropagation();
            }

            var context = this;
            context.locationEnabled = !context.locationEnabled;
            if (navigator.geolocation && context.locationEnabled == true) {
                await this.getCurrentPosition();
            } else {
                context.ClearGeolocation();
                await context.getSites();
            }
        },
        async getSites() {
            // Get nearest site
            var getNearestSitesUrl = this.$contentApiUrl + "/Sites/";

            if (this.HasGeolocation()) {
                var location = this.GetGeolocation();
                getNearestSitesUrl +=
                    "/Coordinates" +
                    "?lng=" +
                    location.lng +
                    "&lat=" +
                    location.lat +
                    "&filterActiveSites=true";
            }
            // else if(this.HasLocation()){
            //   location = this.GetLocation();
            //   getNearestSitesUrl +=
            //   "/Location" +
            //   "?cid=" +
            //   location.cid +
            //   "&rid=" +
            //   location.rid +
            //   "&srid=" +
            //   location.srid;
            // }
            else getNearestSitesUrl += "All?filterActiveSites=true";

            var response = await axios.get(getNearestSitesUrl);
            this.PopulateSitesList(response.data.Result);
        },
        PopulateSitesList(sites) {
            var context = this;
            this.sites = sites;
            this.orderedSites = [];
            this.sitesAtoZ = [];
            var site = null;

            if (context.locationEnabled) {
                for (let index = 0; index < this.sites.length; index++) {
                    site = this.sites[index];
                    this.orderedSites.push({
                        ID: site.ID,
                        Name: site.Name,
                        Distance: Math.round(site.Distance),
                        Class: "",
                        LogoStyle: "background-image: url(" + this.$contentApiUrl + "/Sites/" + site.ID + "/Image/Logo?w=80&h=80)",
                        BannerStyle: "background-image: url(" + this.$contentApiUrl + "/Sites/" + site.ID + "/Image/Banner)"
                    });
                }
            }
            else {
                // Create a unique list of Alpha characters from sites array
                for (let index = 0; index < this.sites.length; index++) {
                    var name = this.sites[index].Name;
                    this.sitesAtoZ.push(name.charAt(0).toUpperCase());
                }

                // Convert array to distinct list
                this.sitesAtoZ = [...new Set(this.sitesAtoZ)];

                for (let index = 0; index < this.sitesAtoZ.length; index++) {
                    var char = this.sitesAtoZ[index];
                    this.orderedSites.push({
                        ID: char,
                        Name: char,
                        Class: "alpha",
                    });

                    for (let index = 0; index < this.sites.length; index++) {
                        site = this.sites[index];
                        if (site.Name.charAt(0).toUpperCase() == char) {
                            this.orderedSites.push({
                                ID: site.ID,
                                Name: site.Name,
                                Class: "",
                            });
                        }
                    }
                }
            }
            this.loading = false;
        },
        getCurrentPosition() {
            var context = this;
            return new Promise((resolve) => {
                navigator.geolocation.getCurrentPosition(
                    async function (position) {
                        context.locationEnabled = true;
                        context.latitude = position.coords.latitude;
                        context.longitude = position.coords.longitude;
                        context.SetGeolocation(context.latitude, context.longitude);

                        // Get nearest site
                        await GetNearestSite();
                        // Get Region
                        await GetRegion();
                        //Resolve the promise
                        return resolve();

                        //Internal helper functions
                        async function GetNearestSite() {
                            var getNearestSiteUrl =
                                context.$contentApiUrl +
                                "/Nearest" +
                                "/Sites/" +
                                "?lng=" +
                                context.longitude +
                                "&lat=" +
                                context.latitude;

                            var response = await axios.get(getNearestSiteUrl);
                            context.nearestSite = response.data.Result[0];
                            context.nearestSiteID = context.nearestSite.ID;
                            context.PopulateSitesList(response.data.Result);
                        }
                        async function GetRegion() {
                            var getRegionUrl =
                                context.$contentApiUrl +
                                "/Location" +
                                "?lat=" +
                                context.latitude +
                                "&lng=" +
                                context.longitude;

                            var response = await axios.get(getRegionUrl);
                            context.location = response.data.Result;
                            context.country = context.location.Country.ID;
                            context.region = context.location.Region.ID;
                            context.subRegionID = context.location.SubRegion.ID;
                            context.subRegionName = context.location.SubRegion.Name;

                            context.subRegionBannerStyle =
                                "background-image: url(" +
                                context.$contentApiUrl +
                                "/Countries/Regions/SubRegions/" +
                                context.subRegionID +
                                "/Image/Banner)";
                        }
                    },
                    async function (error) {
                        switch (error.code) {
                            case error.PERMISSION_DENIED:
                                context.locationEnabled = false;
                                console.log("User denied the request for Geolocation.");
                                context.ClearGeolocation();
                                await context.getSites();
                                break;
                            case error.POSITION_UNAVAILABLE:
                                context.locationEnabled = false;
                                console.log("Location information is unavailable.");
                                context.ClearGeolocation();
                                await context.getSites();
                                break;
                            case error.TIMEOUT:
                                context.locationEnabled = false;
                                console.log("The request to get user location timed out.");
                                context.ClearGeolocation();
                                await context.getSites();
                                break;
                            case error.UNKNOWN_ERROR:
                                context.locationEnabled = false;
                                console.log("An unknown error occurred.");
                                context.ClearGeolocation();
                                await context.getSites();
                                break;
                        }
                        return resolve();
                    }
                );
            });
        }
    }
}
</script>
<!-- Styles -->
<style scoped>
div.view-container .section-title {
    background: #444444;
    color: white;
}

#site-banner-container,
#sub-region-banner-container {
    width: 100%;
    height: 120px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: white;
}

#site-banner-container {
    padding-top: 20px;
}

#site-logo-container {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 0px auto;
}

.section-title {
    text-align: center;
}

svg[data-icon="map-marker"] {
    color: #40a5ff;
}

div.location {
    height: 56px;
}

div.location>div {
    display: inline-block;
    width: auto;
    height: 56px;
}

div.location>div:first-child {
    float: left;
    width: 72px;
    line-height: 56px;
    text-align: center;
}

div.location>div:nth-child(2) {
    padding: 3px 0;
}

div.location>div:last-child {
    float: right;
    line-height: 56px;
    margin-right: 20px;
    padding-top: 15px !important;
}

/* Regions */
ul[name="sites"] {
    padding: 0;
}

ul[name="sites"] li {
    list-style-type: none;
    border-bottom: solid 1px #eaeaea;
    padding: 15px;
    font-size: 17px;
    font-weight: 500;
}

ul[name="sites"] li.alpha {
    background-color: #444444;
    color: white;
    padding: 0px;
    padding-left: 15px;
}

ul[name="sites"] li a {
    color: black;
    display: block;
    text-decoration: none;
}
</style>