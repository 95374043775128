<template>
  <div>
    <ul class="site-list">
      <li v-for="site in sites" :key="site.ID">
        <router-link :to="'/categories?sid=' + site.ID + '&group=' + groupName">
          <div
            class="site-banner-container"
            :style="
              'background-image: url(' +
              apiUrl +
              '/Sites/' +
              site.ID +
              '/Image/Banner)'
            "
          >
            <div
              class="site-logo-container"
              :style="
                'background-image: url(' +
                apiUrl +
                '/Sites/' +
                site.ID +
                '/Image/Logo?w=80&h=80)'
              "
            ></div>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<!-- Scripts -->
<script>
const axios = require("axios");
export default {
  name: "Sites",
  components: {},
  data() {
    return {
      apiUrl: this.$contentApiUrl,
      country: this.$route.query.cid,
      region: this.$route.query.rid,
      subRegion: this.$route.query.srid,
      groupID: this.$route.query.gid,
      groupName: this.$route.query.group,
      sites: [],
    };
  },
  created() {
    axios
      .get(
        this.$contentApiUrl +
          "/Countries/" +
          this.country +
          "/Regions/" +
          this.region +
          "/SubRegions/" +
          this.subRegion +
          "/Groups/" +
          this.groupID +
          "/Categories"
      )
      .then((response) => {
        this.sites = [];
        for (var i = 0; i < response.data.Result.length; i++) {
          if (response.data.Result[i].IsSite) {
            this.sites.push(response.data.Result[i]);
          }
        }
      });
  },
};
</script>
<!-- Styles -->
<style scoped>
.site-banner-container {
  width: 100%;
  height: 120px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 20px;
}
.site-logo-container {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0px auto;
}
.site-list {
  list-style: none;
  padding: 0px;
}
.site-list li {
  margin-bottom: 5px;
  border-bottom: 1px solid black;
}
</style>
