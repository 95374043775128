<template>
  <div id="main-breadcrumb">
    <ul class="breadcrumb-list">
      <li :key="item" v-for="item in crumbItems">{{ item }}</li>
    </ul>
  </div>
</template>
<!-- Scripts -->
<script>
export default {
  name: "Breadcrumb",
  props: {
    items: Array
  },
  data() {
    return {
      crumbItems: []
    };
  },
  created() {
    this.crumbItems = this.items;
  }
};
</script>
<!-- Styles -->
<style scoped>
#main-breadcrumb {
  padding-left: 5px;
  background-color: #369ed5 !important;
  color: #ffff;
  height: 25px;
}
ul.breadcrumb-list {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
ul.breadcrumb-list li {
  display: inline;
  font-size: 14px;
}
ul.breadcrumb-list li + li:before {
  padding-left: 5px;
  color: #fff;
  content: ">\00a0";
}
</style>