<template>
  <div class="ad-container" v-html="bannerhtmlContent"></div>
</template>
<!-- Scripts -->
<script>
const axios = require("axios");
export default {
  name: "AdBanner",
  props: {
    siteID: Number
  },
  data() {
    return {
      bannerhtmlContent: ""
    };
  },
  created(){
    this.GetBannerHtmlContent();
  },
  methods:{
    async GetBannerHtmlContent(){
      var response = await axios.get(this.$contentApiUrl + "/mobilebannerad?sid=" + this.siteID);
      this.bannerhtmlContent = response.data;
    }
  }
};
</script>
<!-- Styles -->
<style>
.ad-container img {
  width: 100%;
  height: 80px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
</style>