<template>
    <div>
        <!-- Breadcrumb -->
        <app-breadcrumb></app-breadcrumb>

        <div class="view-container">
            <h3 class="center">Review the deal experience</h3>
            <h6 class="center">for</h6>
            <span class="center">{{ deal.Name }}</span>
            <template v-if="listingName !== undefined || listingName !== ''">
                <h6 class="center">from</h6>
                <span class="center">{{ listingName }}</span>
            </template>
            <template v-if="siteName !== undefined || siteName !== ''">
                <h6 class="center">at</h6>
                <span class="center">{{ siteName }}</span>
            </template>
            <h5 class="center">Rate the experience</h5>
            <div class="center space-sm">
                <star-rating :border-width="4" border-color="#d8d8d8" :rounded-corners="true" :show-rating="false"
                    :rating=0 @rating-selected="setExperienceRating"
                    :star-points="[23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46, 19, 31, 17]"
                    :glow="experienceRating * 2">
                </star-rating>
            </div>
            <h5 class="center space">Rate the product</h5>
            <div class="center space-sm">
                <star-rating :border-width="4" border-color="#d8d8d8" :rounded-corners="true" :show-rating="false"
                    :rating=0 @rating-selected="setProductRating"
                    :star-points="[23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46, 19, 31, 17]"
                    :glow="productRating * 2">
                </star-rating>
            </div>
            <h5 class="center space">Any remarks?</h5>
            <div class="center space-sm">
                <textarea id="text-remarks" v-model="remarks"
                    placeholder="Describe your experience (optional)"></textarea>
            </div>
            <div class="center space-sm">
                <button id="btn-rate" @click="rate">RATE</button>
            </div>
        </div>
    </div>
</template>
<script>
import BreadcrumbPartial from "@/components/BreadcrumbPartial.vue";
import StarRating from "vue-star-rating"

const axios = require("axios");

export default {
    name: "Giftcard",
    components: {
        "app-breadcrumb": BreadcrumbPartial,
        StarRating
    },
    mixins: [],
    data() {
        return {
            experienceRating: 0,
            productRating: 0,
            remarks: '',
            deal: {},
            listingName: "",
            siteName: "",
            dealID: parseInt(this.$route.query.id),
            UID: this.$route.query.uid
        };
    },
    created() {
        var _self = this;
        // Get the deal's info
        var getDealUrl = this.$contentApiUrl + "/Sites/Listings/Deals?id=" + this.dealID;
        axios.get(getDealUrl).then((response) => {
            _self.deal = response.data.Result;
        });
        // Get reservation's info
        axios.get(this.$contentApiUrl + "/Sites/Listings/Deals/ReservationInfo?UID=" + this.UID).then((response) => {
            _self.listingName = response.data.Result.ListingName;
            _self.siteName = response.data.Result.SiteName;
        });
    },
    methods: {
        setExperienceRating: function (rating) {
            this.experienceRating = rating;
        },
        setProductRating: function (rating) {
            this.productRating = rating;
        },
        rate: function () {
            if (this.productRating < 1 || this.experienceRating < 1)
                return;
            else if (this.productRating < 3 || this.experienceRating < 3)
                //Prompt the user for more informaiton
                this.showAskMessage("Sorry about that");
            else {
                //Insert review through API
                this.postReview(this.remarks);
                this.showSuccessMessage(this);
            }
        },
        showSuccessMessage(instance) {
            this.$swal({
                title: "Rated!",
                text: "Thank you!",
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
                timerProgressBar: true
            }).then(() => {
                instance.$router.replace('/');
            });
        },
        showAskMessage(title) {
            var self = this;
            this.$swal({
                title: title,
                //text: "Please select a problem closely relating to your issue.",
                input: "select",
                icon: "question",
                allowOutsideClick: false,
                inputOptions: {
                    'Product': {
                        'Bad quality product': 'Bad quality product',
                        'Not what was promised': 'Not what was promised'
                    },
                    'Service': {
                        'Bad service': 'Bad service'
                    },
                    'App': {
                        'App is confusing': 'App is confusing',
                        'App is frustrating': 'App is frustrating'
                    }
                },
                inputPlaceholder: 'What was the issue?',
                showCancelButton: true,
                cancelButtonText: 'Close',
                inputValidator: (value) => {
                    return new Promise((resolve) => {
                        if (value === "") {
                            resolve('Please select a problem closest relating to your issue')
                        } else {
                            resolve();
                        }
                    }).finally(function () {
                        if (value !== '') {
                            self.postReview('(' + value + '): ' + self.remarks);
                            self.showSuccessMessage(self);
                        }
                    })
                }
            });
        },
        postReview(remarks) {
            var url = this.$contentApiUrl + '/Deal/Review';
            var model = {
                dealID: this.dealID,
                UID: this.UID,
                experienceRating: this.experienceRating,
                productRating: this.productRating,
                remarks: remarks
            }
            const headers = {
                'Content-Type': 'application/json'
            }
            axios.post(url, JSON.stringify(model), { headers: headers });
        }
    },
};
</script>
<style>
.center {
    text-align: center;
    margin: auto;
}

.space {
    margin-top: 30px;
}

.space-sm {
    margin-top: 15px;
}

#btn-rate {
    text-align: center;
    font-weight: bold;
    color: azure;
    height: 60px;
    width: 120px;
    background-color: #41a3db;
    border-radius: 20px;
    box-shadow: 0px 0px 2px 0px;
    margin-bottom: 15px;
    margin-top: 10px;
}

#text-remarks {
    width: 300px;
    height: 125px;
    border-width: 3px;
}
</style>