<template>
  <div>
    <!-- Breadcrumb -->
    <app-breadcrumb :items="breadcrumbs"></app-breadcrumb>

    <div class="view-container">
      <!-- Show spash screen -->
      <div v-if="showLoader">
        <app-loader :fadeoutLoader="fadeoutLoader"></app-loader>
      </div>
      <div v-if="fadeoutLoader">
        <app-banner></app-banner>
        <app-bottom-banner></app-bottom-banner>
      </div>
    </div>
  </div>
</template>
<!-- Scripts -->
<script>
import BannerPartial from "@/components/BannerPartial.vue";
import AdBannerPartial from "@/components/AdBannerPartial.vue";
import LoaderPartial from "@/components/LoaderPartial.vue";
import BreadcrumbPartial from "@/components/BreadcrumbPartial.vue";

import { splashScreen } from "@/mixins/splashScreen";

export default {
  name: "Home",
  components: {
    "app-banner": BannerPartial,
    "app-bottom-banner": AdBannerPartial,
    "app-loader": LoaderPartial,
    "app-breadcrumb": BreadcrumbPartial
  },
  mixins: [splashScreen],
  data() {
    return {
      breadcrumbs: []
    };
  },
  created() {
    var _self = this;
    function init(){ _self.breadcrumbs = ["South Africa"]; }
    init();
  },
  mounted() {
    this.initSplashScreen();
  }
};
</script>
<style>
html, body {
  height: 100%;
}
</style>
