<template>
  <div :class="{ loader: true, fadeout: fadeoutLoader }">
    <img class="logo pulse" src="@/assets/logos/ipoint-logo-450x225.png" />
    <span class="loader-title"> <b>FIND</b> WHAT YOU'RE LOOKING FOR... </span>
  </div>
</template>
<!-- Scripts-->
<script>
export default {
  name: "LoaderPartial",
  props: {
    fadeoutLoader: Boolean,
  },
};
</script>
<!-- Styles -->
<style scoped>
.logo {
  width: 100%;
}
.loader {
  background-color: #000;
  color: white;
  display: block;
  font-size: 32px;
  overflow: hidden;
  padding-top: 10vh;
  text-align: center;
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loader-title {
  display: block;
  margin: 0 auto;
  text-align: center;
  width: 128px;
  font-size: 25px;
}

.fadeout {
  animation: fadeout 1s forwards;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}

.pulse {
  animation-name: pulse;
  animation-duration: 3000ms;
  transform-origin: 70% 70%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>