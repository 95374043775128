<template>
  <div>
    <!-- Breadcrumb -->
    <app-breadcrumb :items="breadcrumbs"></app-breadcrumb>

    <div class="view-container page-container" v-show="showDetails">
      <!-- Title -->
      <span class="title">{{ deal.Title }}</span>
      <!-- Available at -->
      <span class="sp-available-at">
        <i class="fa fa-map-marker"></i>
        {{ this.availableAtText }}
      </span>
      <img :src="dealImageUrl" class="img-deal" />
      <!-- Tabs -->
      <div class="tabs">
        <b-card no-body>
          <b-tabs content-class="mt-3" card>
            <b-tab title="Description" active>
              <b-card-text>{{ deal.Description }}</b-card-text>
            </b-tab>
            <b-tab title="T&amp;C's">
              <b-card-text>{{ deal.TermsAndConditions }}</b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>

    <!-- Reservation -->
    <div class="view-container" v-show="showReservation">
      <div class="redemption-instructions">
        <small>
          <b>Privacy policy</b>
          <br />We will not share or sell your personal data.
          <br />
          <br />
          <b>How it works</b>
          <br />The reason we request a mobile number / email address is so that
          we can tie your contact to your reservation. Your deal reservation
          link will be sent to you.
        </small>
        <br />
        <br />
        <h5>RESERVE YOUR DEAL:</h5>
        <div>
          <div>
            <input type="text" class="txbPhoneNumber" placeholder="e.g. 012 345 6789" v-model="phoneNumber"
              v-show="showPhoneNumber" />
            <input type="text" class="txbEmailAddress" placeholder="e.g. john@ipoint.co.za" v-model="emailAddress"
              v-show="showEmailAddress" />
            <span class="btnReserve" v-on:click="reserveDeal">
              <i class="fa fa-long-arrow-right"></i>
            </span>

            <a href="javascript:;" id="btn-show-email" class="display-block margin-top-sm" v-show="showPhoneNumber"
              v-on:click="toggleEmailPhoneNumber">Want to use your email address?</a>
            <a href="javascript:;" id="btn-show-number" class="display-block margin-top-sm" v-show="showEmailAddress"
              v-on:click="toggleEmailPhoneNumber">Want to use your phone number?</a>

            <small>Enter your mobile number or email above to reserve it.</small>
          </div>
        </div>
        <a class="btnBack" v-on:click="showDealDetails">Back</a>
      </div>
      <div class="tabs">
        <b-card no-body>
          <b-tabs content-class="mt-3" card>
            <b-tab title="Available at">
              <b-card-text>
                <!-- Stores -->
                <ul class="listings">
                  <li v-for="listing in dealListings" :key="listing.ID" v-bind:class="{ active: listing.Active }"
                    v-on:click="setSelectedListing(listing)">
                    <img :src="listing.ImageUrl" />
                    {{ listing.Name }} ({{ listing.SiteName }})
                  </li>
                </ul>
                <!-- Map -->
                <SvgPanZoom style="height: 100%; background-color: rgb(238 238 238)" :zoomEnabled="true"
                  :controlIconsEnabled="true" :fit="false" :center="false" @svgpanzoom="registerSvgPanZoom">
                  <svg ref="mapElement" name="mapElement"></svg>
                </SvgPanZoom>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>

    <!-- Redemption -->
    <div class="view-container" style="min-height: 580px" v-show="showRedemption">
      <div class="redemption-instructions">
        <small v-if="DealCodeRetrieved">
          <b>How to</b>
          <br />Go to your nearest store and present the clerk with your {{couponTitle}}.
        </small>
        <br />
        <b v-if="!DealCodeRetrieved">Fetching deal code...</b>
        <br />
        <b-spinner v-if="!DealCodeRetrieved" variant="primary" label="Spinning"></b-spinner>
        <div id="qrcode"></div>
        <br />
        <template v-if="DealCodeRetrieved">
          <h6>{{couponTitle}}:</h6>
          <h1 v-if="this.DealCode !== null && this.DealCode !== 'undefined'">{{ DealCode.substring(0, 3) + ' ' +
              DealCode.substring(3, 6) + ' ' + DealCode.substring(6, 9)
          }}</h1>
          <b>This code will expire on:</b>
          <br />
          {{ coupon.redeemToDate | couponExpiryDateFormat }}
        </template>
        <!-- <div>
          <div>
            <input
              type="text"
              class="txbPassword"
              placeholder="Enter the password"
              v-model="password"
            />
            <span class="btnRedeem" v-on:click="redeemDeal">
              <i class="fa fa-long-arrow-right"></i>
            </span>
          </div>
        </div> -->
        <a class="btnBack" v-on:click="showDealDetails">Back</a>
      </div>
      <!-- Available at -->
      <div class="tabs">
        <b-card no-body>
          <b-tabs content-class="mt-3" card>
            <b-tab title="Available at">
              <b-card-text>
                <!-- Stores -->
                <ul class="listings">
                  <li v-for="listing in dealListings" :key="listing.ID" v-bind:class="{ active: listing.Active }"
                    v-on:click="setSelectedListing(listing)">
                    <img :src="listing.ImageUrl" />
                    {{ listing.Name }} ({{ listing.SiteName }})
                  </li>
                </ul>
                <!-- Map -->
                <SvgPanZoom style="height: 100%; background-color: rgb(238 238 238)" :zoomEnabled="true"
                  :controlIconsEnabled="true" :fit="false" :center="false" @svgpanzoom="registerSvgPanZoom">
                  <svg ref="mapElement" name="mapElement"></svg>
                </SvgPanZoom>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>

    <div class="footer-menu">
      <div class="deal-deal-price">
        <span class="amount">R{{ deal.DealPrice }}</span>
        <span class="tag">YOU PAY:</span>
      </div>
      <div class="deal-retail-price">
        <span class="amount">R{{ deal.RetailPrice }}</span>
        <span class="tag">VALUE:</span>
      </div>
      <div class="deal-discount">
        <span class="amount">R{{ deal.RetailPrice - deal.DealPrice }}</span>
        <span class="tag">YOU SAVE:</span>
      </div>
      <div class="deal-footer-info">
        <div class="deal-expiry-date">
          <h6 class="deal-expiry-date-value">
            {{ deal.ExpiryDate | expiryDateFormat }}
          </h6>
          <h6 class="deal-expiry-date-tag">EXPIRY DATE:</h6>
        </div>
        <div class="deal-available-units">
          <h6 class="deal-available-units-value">
            {{ deal.StockCount - deal.StockRedeemed }}
          </h6>
          <h6 class="deal-available-units-tag">AVAILABLE UNITS:</h6>
        </div>
      </div>
      <span class="btn-deal-reserve-footer" v-on:click="showDealDetails" v-show="showRedemptionButton">{{
          this.btnRedeemText
      }}</span>
    </div>
  </div>
</template>
<!-- Scripts -->
<script>
import BreadcrumbPartial from "@/components/BreadcrumbPartial.vue";

// SVG Map
import SvgPanZoom from "vue-svg-pan-zoom";

// Moment (formatting dates)
import moment from "moment";

import qrcode from "qrcode-js-package";

const axios = require("axios");

export default {
  name: "Listing",
  components: {
    "app-breadcrumb": BreadcrumbPartial,
    SvgPanZoom: SvgPanZoom,
  },
  methods: {
    moment: function () {
      return moment();
    },
    showMessage(msg) {
      this.$swal(msg);
    },
    showSuccessMessage(title, msg) {
      this.$swal({
        title: title,
        text: msg,
        icon: "success",
      });
    },
    showWarningMessage(title, msg) {
      this.$swal({
        title: title,
        text: msg,
        icon: "warning",
      });
    },
    showErrorMessage(title, msg) {
      this.$swal({
        title: title,
        text: msg,
        icon: "error",
      });
    },
    registerSvgPanZoom(svgpanzoom) {
      this.svgMapElement = svgpanzoom;
    },
    reserveDeal: function () {
      var getReservationUrl =
        this.baseUrl +
        "/Sites/Listings/Deals/" +
        this.dealID +
        "/GetReservationLink?sid=" +
        this.siteID +
        "&kid=" +
        this.kioskID +
        "&oid=" +
        this.orientationID +
        "&did=" +
        this.dealID;

      this.selectedListing = this.getSelectedListing();
      if (this.selectedListing != null)
        getReservationUrl += "&lid=" + this.selectedListing.ID;
      else
        this.showWarningMessage('Could not reserve deal', 'Select a store where you would like to reserve the deal from. You\'ll find this under the "Available At" tab.');

      if (this.showPhoneNumber) {
        var mobileNumber = this.phoneNumber;
        if (!mobileNumber || mobileNumber === "") {
          this.showWarningMessage("Error", "Please enter your phone number.");
          return false;
        }
        mobileNumber = mobileNumber.replaceAll(" ", "");
        if (mobileNumber.length < 10) {
          this.showWarningMessage("Error", "Invalid phone number.");
          return false;
        }

        getReservationUrl += "&mobileNumber=" + mobileNumber;
      } else {
        if (!this.validateEmail(this.emailAddress)) {
          this.showWarningMessage("Invalid email address.");
          return false;
        }
        getReservationUrl += "&emailAddress=" + this.emailAddress;
      }

      var self = this;
      axios.get(getReservationUrl).then((response) => {
        if (response.data.Result.success) {
          self.showSuccessMessage("Success!", response.data.Result.message);
          self.showRedemptionButton = false;
        } else {
          self.showErrorMessage("Reservation failed!", response.data.Result.message);
        }
      });
    },
    redeemDeal: function () {
      if (!this.password || this.password.length === 0) {
        this.showWarningMessage("Error", "Please enter a password.");
        return false;
      }

      var getRedemptionUrl =
        this.baseUrl +
        "/Sites/Listings/Deals/" +
        this.dealID +
        "/Redeem?sid=" +
        this.siteID +
        "&did=" +
        this.dealID +
        "&password=" +
        this.password;

      if (this.reservationID) getRedemptionUrl += "&rid=" + this.reservationID;
      if (this.kioskID) getRedemptionUrl += "&kid=" + this.kioskID;

      if (this.selectedListing != null)
        getRedemptionUrl += "&lid=" + this.selectedListing.ID;

      var self = this;
      axios.get(getRedemptionUrl).then((response) => {
        var success = response.data.Result.Success;
        if (success) {
          self.showSuccessMessage("Success!", "User may now collect the deal.");
          self.showRedemptionButton = false;
          // Clear the password field
          self.password = "";
        } else {
          self.showErrorMessage(
            "Error",
            "Could not redeem deal. " + response.data.Result
          );
        }
      });
    },
    initMap: function (listingID) {
      var getMapUrl =
        this.baseUrl +
        "/Sites/" +
        this.siteID +
        "/Listings/" +
        listingID +
        "/Maps/";

      if (this.orientationID) {
        var mapOrientation = this.getOrientationById(this.orientationID);
        getMapUrl += mapOrientation + "?";
        this.appendMap(getMapUrl);
      } else {
        var getSiteOrientationsUrl =
          this.baseUrl + "/Sites/" + this.siteID + "/Maps/Orientations";
        // Else if we don't have the orientation, get the site's default orientation, append to URL and get the map in that orientation
        axios.get(getSiteOrientationsUrl).then((response) => {
          var siteMapData = response.data.Result;
          var mapOrientation = this.getOrientation(siteMapData[0].Name);
          getMapUrl += mapOrientation + "?";
          this.appendMap(getMapUrl);
        });
      }
    },
    appendMap: function (getMapUrl, showPlots) {
      var self = this;

      if (this.kioskID) getMapUrl += "kid=" + this.kioskID + "&";
      getMapUrl += "w=640&h=480&imageType=SVG";
      if (showPlots) getMapUrl += "&showGrid=true";

      axios.get(getMapUrl).then((response) => {
        var parser = new DOMParser();
        var xmlDoc = parser.parseFromString(response.data, "text/xml");
        var mapSvg = "";
        if (xmlDoc.getElementsByTagName("svg").length > 0) {
          var mapNodes = xmlDoc.getElementsByTagName("svg")[0].childNodes;
          for (var i = 0; i < mapNodes.length; i++) {
            mapSvg += mapNodes[i].outerHTML;
          }
        }

        self.$refs["mapElement"].childNodes[0].innerHTML = mapSvg;
        self.$nextTick(() => {
          self.svgMapElement.zoom(0.7);
        });
      });
    },
    getOrientationById(orientationId) {
      var abbreviation;
      switch (orientationId) {
        case 1:
          abbreviation = "N";
          break;
        case 2:
          abbreviation = "NE";
          break;
        case 3:
          abbreviation = "E";
          break;
        case 4:
          abbreviation = "SE";
          break;
        case 5:
          abbreviation = "S";
          break;
        case 6:
          abbreviation = "SW";
          break;
        case 7:
          abbreviation = "W";
          break;
        case 8:
          abbreviation = "NW";
          break;
      }

      return abbreviation;
    },
    getOrientation(orientation) {
      var abbreviation;
      switch (orientation) {
        case "North":
          abbreviation = "N";
          break;
        case "North East":
          abbreviation = "NE";
          break;
        case "East":
          abbreviation = "E";
          break;
        case "South East":
          abbreviation = "SE";
          break;
        case "South":
          abbreviation = "S";
          break;
        case "South West":
          abbreviation = "SW";
          break;
        case "West":
          abbreviation = "W";
          break;
        case "North West":
          abbreviation = "NW";
          break;
      }

      return abbreviation;
    },
    setSelectedListing: function (listing) {
      this.dealListings.forEach(function (dealListing) {
        dealListing.Active = dealListing.ID == listing.ID;
      });
      this.initMap(listing.ID);
    },
    getSelectedListing: function () {
      for (var i = 0; i < this.dealListings.length; i++) {
        if (this.dealListings[i].Active) {
          return this.dealListings[i];
        }
      }
      return null;
    },
    showDealDetails: function () {
      if (this.$route.query.rid) {
        this.showDetails = !this.showDetails;
        this.showRedemption = !this.showRedemption;

        var getDealRedemptionUrl = this.baseUrl + "/Sites/Listings/Deals/Coupon?did=" + this.dealID + "&rid=" + this.reservationID;
        if (!this.DealCodeRetrieved) {
          axios.get(getDealRedemptionUrl).then((response) => {
            this.coupon = response.data.Result.coupon;
            this.DealCode = response.data.Result.DealCode;
            this.couponTitle = response.data.Result.couponTitle;
            new qrcode(document.getElementById("qrcode"), '' + this.DealCode);
            this.DealCodeRetrieved = true;
          });
        }
      } else {
        if (this.showReservation == false && this.getSelectedListing() == null)
          this.showWarningMessage('Select a listing', 'Please go to the "Available At" tab first and select a listing to reserve the deal from');
        else {
          this.showReservation = !this.showReservation;
          this.showDetails = !this.showDetails;
        }
      }
    },
    toggleEmailPhoneNumber: function () {
      this.showPhoneNumber = !this.showPhoneNumber;
      this.showEmailAddress = !this.showEmailAddress;
    },
    validateEmail: function (email) {
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      );
    },
  },
  filters: {
    expiryDateFormat: function (date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    couponExpiryDateFormat: function (date) {
      return moment(date).format("DD-MMM-YYYY HH:mm");
    },
  },
  data() {
    return {
      deal: {},
      siteID: parseInt(this.$route.query.sid),
      kioskID: parseInt(this.$route.query.kid),
      dealID: parseInt(this.$route.query.did),
      listingID: parseInt(this.$route.query.lid),
      orientationID: parseInt(this.$route.query.oid),
      reservationID: this.$route.query.rid,
      baseUrl: this.$contentApiUrl,
      dealImageUrl: "",
      availableAtText: "",
      selectedListing: null,
      dealListings: [],
      breadcrumbs: ["Deal"],
      DealCodeRetrieved: false,
      showDetails: true,
      showRedemption: false,
      showReservation: false,
      showPhoneNumber: true,
      showEmailAddress: false,
      showRedemptionButton: true,
      btnRedeemText: "Reserve",
      phoneNumber: null,
      emailAddress: null,
      password: null,
      svgMapElement: null,
      coupon: {},
      DealCode: null,
      couponTitle: ""
    };
  },
  created() {
    var _self = this;

    if (_self.$route.query.rid) {
      _self.btnRedeemText = "Redeem";
    }

    var getDealUrl = this.baseUrl + "/Sites/Listings/Deals?id=" + this.dealID;
    axios.get(getDealUrl).then((response) => {
      _self.deal = response.data.Result;
    });

    _self.dealImageUrl =
      _self.baseUrl + "/Sites/Listings/Deals/" + _self.dealID + "/Image/Ad";

    var dealListingsUrl =
      _self.baseUrl + "/Sites/Listings/Deal/" + _self.dealID;

    //Log that the deal was viewed
    if (!_self.reservationID || _self.reservationID === 'undefined')
      axios.get(this.baseUrl + "/log/Web/Deals/Viewed?did=" + _self.dealID + "&sid=" + _self.siteID + "&lid=" + _self.listingID + "&kid=" + _self.kioskID);

    axios.get(dealListingsUrl).then((response) => {
      var dealListingArray = [];
      var activeListing = true;

      for (var i = 0; i < response.data.Result.length; i++) {
        var listing = response.data.Result[i];
        if (_self.siteID) {
          if (listing.SiteID == _self.siteID) {
            var listingItem = {
              ID: listing.ID,
              Name: listing.Name,
              SiteName: listing.SiteName,
              Active: activeListing,
              ImageUrl:
                _self.baseUrl +
                "/Sites/Listings/" +
                listing.ID +
                "/Image/Logo?w=30&h=30",
            };
            dealListingArray.push(listingItem);
          }
        } else {
          dealListingArray.push({
            ID: listing.ID,
            Name: listing.Name,
            SiteName: listing.SiteName,
            Active: activeListing,
            ImageUrl:
              self.baseUrl +
              "/Sites/Listings/" +
              listing.ID +
              "/Image/Logo?w=30&h=30",
          });
        }
        activeListing = false;
      }

      if (dealListingArray.length > 0) {
        for (var a = 0; a < dealListingArray.length; a++) {
          if (dealListingArray[a].ID == _self.listingID) {
            _self.setSelectedListing(dealListingArray[a]);
          }
        }
      }

      var listingsCount = dealListingArray.length;
      dealListingArray.forEach(function (listing) {
        if (listingsCount == 1)
          _self.availableAtText += listing.Name + " (" + listing.SiteName + ")";
        else _self.availableAtText = "Available at multiple venues";
      });

      _self.dealListings = dealListingArray;

      _self.selectedListing = _self.getSelectedListing();
      if (_self.selectedListing == null && dealListingArray.length == 1)
        _self.setSelectedListing(dealListingArray[0]);
    });
  },
};
</script>
<!-- Styles -->
<style scoped>
.card {
  border: none;
  display: block;
}

.display-block {
  display: block;
}

.margin-top-sm {
  margin-top: 10px;
}

.view-container {
  width: 100%;
  background-color: #d9d9d9 !important;
  padding: 10px 15px;
}

.view-container .redemption-instructions {
  margin-top: 50px;
  background-color: #eeeeee;
  min-height: 46px;
  margin-bottom: 8px;
  padding: 10px 10px;
  width: 100%;
  text-align: center;
  color: black;
}

.view-container .redemption-instructions input.txbPassword,
.view-container .redemption-instructions input.txbPhoneNumber,
.view-container .redemption-instructions input.txbEmailAddress {
  width: 60%;
  padding: 8px;
  border: none;
  border-top: 1px solid #d6d6d6;
  color: black;
}

.view-container .redemption-instructions span.btnRedeem,
.view-container .redemption-instructions span.btnReserve {
  padding: 8px 27px;
  background-color: #1b82ec;
  height: 46px;
  vertical-align: middle;
  cursor: pointer;
  font-size: 20px;
  color: white;
}

.view-container .redemption-instructions a.btnBack {
  margin-top: 5px;
  display: block;
  color: #007bff;
  text-decoration: none;
}

.view-container img.img-deal {
  width: 100%;
  height: auto;
}

.view-container .title {
  margin-top: 10px;
  margin-bottom: 15px;
  text-align: center;
  font-size: 20px;
  color: #777;
  font-weight: bold;
  display: block;
}

.view-container span.sp-available-at {
  margin-bottom: 5px;
  font-size: 12px;
  display: block;
}

.view-container div.tabs {
  height: 100%;
  margin-bottom: 100px;
  background-color: #eeeeee;
}

.view-container ul.listings {
  list-style: none;
  font-size: 14px;
}

.view-container ul.listings li {
  width: 205px;
  text-align: center;
  padding: 10px;
  background-color: #d9d9d9;
  border-radius: 5px;
  margin-bottom: 10px;
}

.view-container ul.listings li.active {
  background-color: #1b82ec;
}

.view-container ul.listings li img {
  min-height: 30px;
  width: auto;
  border-radius: 50%;
}

.footer-menu {
  width: 100%;
  height: 100px;
  background: white;
  position: fixed;
  left: 0px;
  bottom: 0px;
}

.deal-deal-price {
  color: black;
  margin-right: 0;
  width: 50%;
  height: 44px;
  padding: 0;
  margin-left: 8px;
}

.deal-deal-price .amount {
  font-size: 26px;
  line-height: 30px;
  font-weight: 700;
  color: #093;
}

.deal-deal-price .tag {
  display: block;
  font-size: 10px;
  line-height: 10px;
  color: #999;
}

.deal-retail-price {
  float: left;
  padding: 6px 10px 0 0;
  margin-left: 8px;
}

.deal-retail-price .amount {
  text-decoration: line-through;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #666;
}

.deal-retail-price .tag {
  display: block;
  font-size: 10px;
  line-height: 10px;
  color: #999;
}

.deal-discount {
  float: left;
  padding: 6px 0 0;
}

.deal-discount .amount {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #1b82ec;
}

.deal-discount .tag {
  display: block;
  font-size: 10px;
  line-height: 10px;
  color: #999;
}

.deal-footer-info {
  color: black;
  position: absolute;
  top: 0px;
  right: 145px;
}

.deal-expiry-date {
  font-size: 12px;
  text-align: left;
  padding-left: 15px;
  margin-top: 6px;
}

.deal-expiry-date .deal-expiry-date-value {
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #666;
  text-transform: uppercase;
}

.deal-expiry-date .deal-expiry-date-tag {
  margin-top: 0px;
  display: block;
  font-size: 10px;
  line-height: 10px;
  color: #999;
  margin-top: 5px;
}

.deal-available-units {
  font-size: 12px;
  text-align: left;
  padding-left: 15px;
  margin-top: 18px;
}

.deal-available-units .deal-available-units-value {
  margin-bottom: 0px;
  margin-top: -5px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #666;
}

.deal-available-units .deal-available-units-tag {
  margin-top: 0px;
  display: block;
  font-size: 10px;
  line-height: 10px;
  color: #999;
}

.btn-deal-reserve-footer {
  padding: 24px 24px;
  background-color: #1b82ec;
  height: 75px;
  vertical-align: middle;
  cursor: pointer;
  font-size: 20px;
  margin-left: 176px;
  float: right;
  position: absolute;
  top: 10px;
  right: 15px;
  font-weight: bold;
  color: white;
}

svg[name="mapElement"] {
  height: 350px;
}
</style>
<style>
div#qrcode img{
  margin:auto;
}
</style>