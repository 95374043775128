<template>
  <div>
    <b-sidebar id="sidebar-backdrop" title="Location" backdrop-variant="dark" backdrop shadow>
      <div>
        <ul class="menu">
          <li class="menu-item">
            <router-link to="/">
              <!--<font-awesome-icon icon="home" class="home"/>-->
              Home
            </router-link>
          </li>
          <li class="menu-item" v-for="country in countries" :key="country.ID">
            {{ country.Name }}
            <font-awesome-icon icon="chevron-right" class="arrow"/>
            <ul>
              <li class="sub-menu-item" v-for="region in country.Regions" :key="region.ID">
                <font-awesome-icon icon="plus" class="plus"/>
                <span>{{ region.Name }}</span>
                <ul>
                  <li
                    class="sub-sub-menu-item"
                    v-for="subRegion in region.SubRegions"
                    :key="subRegion.ID"
                  >
                  <a :href="'/Region?cid=' + country.ID + '&rid=' + region.ID + '&srid=' + subRegion.ID">
                    {{ subRegion.Name }}
                  </a></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </b-sidebar>
  </div>
</template>
<!-- Scripts -->
<script>
const axios = require("axios");
export default {
  name: "Sidebar",
  data() {
    return {
      countries: []
    };
  },
  mounted() {
    axios
      .get(this.$contentApiUrl + "/countries/regions/subregions?filterActiveSites=true")
      .then(response => {
        var results = response.data.Result;
        results.forEach(result => {
          if (this.countries.some(e => e.ID === result.Country.ID)) return;

          // Add country to menu
          var country = {
            ID: result.Country.ID,
            Name: result.Country.Name,
            Regions: []
          };

          this.countries.push(country);

          results.forEach(resultRegion => {
            if (country.Regions.some(e => e.ID === resultRegion.Region.ID))
              return;

            // Add region to country
            if (resultRegion.Region.ParentID == country.ID) {
              var region = {
                ID: resultRegion.Region.ID,
                Name: resultRegion.Region.Name,
                SubRegions: []
              };

              country.Regions.push(region);

              // Add sub regions to region
              results.forEach(resultSubRegion => {
                if (
                  region.SubRegions.some(
                    e => e.ID === resultSubRegion.SubRegion.ID
                  )
                )
                  return;

                if (resultSubRegion.SubRegion.ParentID == region.ID) {
                  var subRegion = {
                    ID: resultSubRegion.SubRegion.ID,
                    Name: resultSubRegion.SubRegion.Name
                  };
                  region.SubRegions.push(subRegion);
                }
              });
            }
          });
        });
      });
  }
};
</script>
<!-- Styles-->
<style scoped>
#sidebar-backdrop ul {
  list-style: none;
  padding: 0px;
}
#sidebar-backdrop li {
  border-bottom: 1px solid #ddd;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 10px;
}
#sidebar-backdrop li ul li:first-child {
  border-top: 1px solid #ddd;
}
#sidebar-backdrop ul li:first-child {
  margin-top: 10px;
}
#sidebar-backdrop li:last-child {
  border: none;
}
#sidebar-backdrop ul.menu li.sub-menu-item svg.plus {
  font-size: 20px;
}
#sidebar-backdrop ul.menu li.sub-menu-item span {
  margin-left: 9px;
  display: inline-block;
}
#sidebar-backdrop ul.menu li.sub-sub-menu-item {
  padding-left: 40px;
}
#sidebar-backdrop ul.menu li svg.arrow {
  float: right;
  margin-top: 5px;
  color: #ddd;
}
#sidebar-backdrop ul.menu li svg.home {
  color: #000;
  margin-right: 5px;
}
</style>