import Vue from 'vue'
import VueRouter from 'vue-router'

// Views
import Home from '../views/Home.vue'
import Sites from '../views/Sites.vue'
import Site from '../views/Site.vue'
import Categories from '../views/Categories.vue'
import Listing from '../views/Listing.vue'
import Listings from '../views/Listings.vue'
import Proximity from '../views/Proximity.vue'
import Info from '../views/Info.vue'
import Deal from '../views/Deal.vue'
import Deals from '../views/Deals.vue'
import Region from '../views/Region.vue'
import Search from '../views/Search.vue'
import QRCScanner from '../views/QRCScanner.vue'
import Giftcard from '../views/Giftcard.vue'
import DealReview from '../views/DealReview.vue'
import Competition from '../views/Competition.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/sites',
    name: 'Sites',
    component: Sites
  },
  {
    path: '/site',
    name: 'Site',
    component: Site
  },
  {
    path: '/categories',
    name: 'Categories',
    component: Categories
  },
  {
    path: '/listing',
    name: 'Listing',
    component: Listing
  },
  {
    path: '/listings',
    name: 'Listings',
    component: Listings
  },
  {
    path: '/',
    name: 'Proximity',
    component: Proximity
  },
  {
    path: '/info',
    name: 'Info',
    component: Info
  },
  {
    path: '/deal',
    name: 'Deal',
    component: Deal
  },
  {
    path: '/deals',
    name: 'Deals',
    component: Deals
  },
  {
    path: '/region',
    name: 'Region',
    component: Region
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  {
    path: '/qrcscanner',
    name: 'QRCScanner',
    component: QRCScanner
  },
  {
    path: '/giftcard',
    name: 'Giftcard',
    component: Giftcard
  },
  {
    path: '/deal/review',
    name: 'DealReview',
    component: DealReview
  },
  {
    path: '/competition',
    name: 'Competition',
    component: Competition
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
