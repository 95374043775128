<template>
  <div>
    <!-- Breadcrumb -->
    <app-breadcrumb
      :items="breadcrumbs"
      :key="breadcrumbUpdated"
    ></app-breadcrumb>

    <div class="view-container">
      <app-banner :subRegionID="subRegionID"></app-banner>
      <div class="container">
        <b-container id="menu-icons">
          <b-row>
            <b-col
              class="menu-item"
              cols="3"
              v-for="group in groups"
              :key="group.ID"
            >
              <router-link
                :to="{
                  path:
                    '/Sites?cid=' +
                    countryID +
                    '&rid=' +
                    regionID +
                    '&srid=' +
                    subRegionID +
                    '&gid=' +
                    group.ID +
                    '&group=' +
                    group.Name,
                }"
                :data-id="group.ID"
              >
                <div class="menu-icon">
                  <img :src="group.ImgUrl" />
                </div>
                <span>{{ group.Name }}</span>
              </router-link>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <app-bottom-banner></app-bottom-banner>
    </div>
  </div>
</template>
<!-- Scripts -->
<script>
import { appLocalStorage } from "@/mixins/appLocalStorage";
import BannerPartial from "@/components/BannerPartial.vue";
import AdBannerPartial from "@/components/AdBannerPartial.vue";
import BreadcrumbPartial from "@/components/BreadcrumbPartial.vue";

const axios = require("axios");

export default {
  name: "Site",
  components: {
    "app-banner": BannerPartial,
    "app-bottom-banner": AdBannerPartial,
    "app-breadcrumb": BreadcrumbPartial,
  },
  data() {
    return {
      site: {},
      countryID: parseInt(this.$route.query.cid),
      regionID: parseInt(this.$route.query.rid),
      subRegionID: parseInt(this.$route.query.srid),
      groups: {},
      navItems: [],
      breadcrumbs: [],
      breadcrumbUpdated: false,
      location: '',
    };
  },
  mixins: [appLocalStorage],
  created() {
    var _self = this;
    // Set location
    this.SetLocation(this.countryID, this.regionID, this.subRegionID);

    var getSubRegionGroupsUrl =
      this.$contentApiUrl +
      "/Countries/" +
      this.countryID +
      "/Regions/" +
      this.regionID +
      "/SubRegions/" +
      this.subRegionID +
      "/Groups";
    axios.get(getSubRegionGroupsUrl).then((response) => {
      _self.groups = response.data.Result;
    });
  },
  methods: {
    
  },
};
</script>
<!-- Styles -->
<style scoped>
#menu-icons {
  margin-top: 10px;
}
#menu-icons a {
  color: #212529;
  text-decoration: none;
}
#menu-icons .menu-item {
  min-height: 76px;
  text-align: center;
  margin-bottom: 10px;
}
#menu-icons .menu-item:active {
  transform: scale(0.98);
}
.menu-item .menu-icon {
  height: 53px;
  width: 53px;
  margin: auto;
}
.menu-icon-row {
  margin-bottom: 10px;
}

#menu-icons div.menu-icon img {
  max-width: 100%;
}
</style>
