<template>
    <div>
        <!-- Breadcrumb -->
        <app-breadcrumb v-bind:items="breadcrumbs" :key="breadcrumbUpdated"></app-breadcrumb>

        <div class="view-container">
            <app-banner :SiteID="siteID" :location="location"></app-banner>
            <div class="container">
                <b-container id="menu-icons">
                    <b-row class="menu-icon-row">
                        <b-skeleton-wrapper :loading="loading">
                            <template #loading>
                                <div class="container">
                                    <b-container id="menu-icons">
                                        <b-row class="menu-icon-row">
                                            <b-col class="menu-item" cols="3" v-for="i in 8" :key="i">
                                                <b-skeleton class="skeleton-icon" type="avatar" size="53px"></b-skeleton>
                                                <b-skeleton class="skeleton-label" animation="wave" width="57px"></b-skeleton>
                                            </b-col>
                                        </b-row>
                                    </b-container>
                                </div>
                            </template>
                            <b-col class="menu-item" cols="3" v-for="(group, i) in groups" :key="i">
                                <!-- If the group is an action -->
                                <template v-if="group.GroupType == 'Action'">
                                    <template v-if="group.Name == 'Deals'">
                                        <router-link :to="'/deals?sid=' + siteID">
                                            <div class="menu-icon">
                                                <img :src="group.ImgUrl" />
                                            </div>
                                            <div class="dealCountIcon">{{ group.DealsCount }}</div>
                                            <span>{{ group.Name }}</span>
                                        </router-link>
                                    </template>
                                    <template v-if="group.Name == 'Scanner'">
                                        <router-link :to="'/qrcscanner'">
                                            <div class="menu-icon">
                                                <img :src="group.ImgUrl" />
                                            </div>
                                            <span>{{ group.Name }}</span>
                                        </router-link>
                                    </template>
                                    <template v-if="group.Name == 'Gift Card'">
                                        <router-link :to="{ path: '/giftcard?id=' + site.ID }">
                                            <div class="menu-icon">
                                                <img :src="group.ImgUrl" />
                                            </div>
                                            <span>{{ group.Name }}</span>
                                        </router-link>
                                    </template>
                                </template>
                                <!-- If the group is a filter -->
                                <template v-else>
                                    <router-link :to="{ path: '/categories?sid=' + siteID + '&group=' + group.Name }"
                                        :data-id="group.ID">
                                        <div class="menu-icon">
                                            <img :src="group.ImgUrl" />
                                        </div>
                                        <div v-if="group.DealsCount > 0" class="dealCountIcon">{{ group.DealsCount }}</div>
                                        <span>{{ group.Name }}</span>
                                    </router-link>
                                </template>
                            </b-col>
                        </b-skeleton-wrapper>
                    </b-row>
                </b-container>
            </div>
            <app-bottom-banner :siteID="siteID"></app-bottom-banner>
        </div>
    </div>
</template>
<!-- Scripts -->
<script>
import BannerPartial from "@/components/BannerPartial.vue";
import AdBannerPartial from "@/components/AdBannerPartial.vue";
import BreadcrumbPartial from "@/components/BreadcrumbPartial.vue";

const axios = require("axios");

export default {
    name: "Site",
    components: {
        "app-banner": BannerPartial,
        "app-bottom-banner": AdBannerPartial,
        "app-breadcrumb": BreadcrumbPartial,
    },
    data() {
        return {
            site: {},
            siteID: parseInt(this.$route.query.id),
            groups: {},
            navItems: [],
            breadcrumbs: [],
            breadcrumbUpdated: false,
            location: '',
            loading: false
        };
    },
    async created() {
        this.loading = true;

        // Get the site's info
        await this.getSiteInfo();
        // Get the site's menu buttons
        await this.getSiteMenuButtons();
        // Get the site's groups
        await this.getSiteGroups();

        this.loading = false;
    },
    methods: {
        async getSiteInfo() {
            var getSiteUrl = this.$contentApiUrl + "/Sites?id=" + this.siteID;
            var response = await axios.get(getSiteUrl);

            this.site = response.data.Result;
            this.breadcrumbs = ["Site", this.site.Name];
            this.breadcrumbUpdated = !this.breadcrumbUpdated;
            this.location = this.site.Name;
        },
        async getSiteMenuButtons() {
            var getSiteMenuUrl =
                this.$contentApiUrl +
                "/Sites/" +
                this.siteID +
                "/Navigation";

            var response = await axios.get(getSiteMenuUrl);
            this.navItems = response.data.Result;
        },
        async getSiteGroups() {
            var getSiteGroupsUrl = this.$contentApiUrl + "/Sites/" + this.siteID + "/Groups";
            var response = await axios.get(getSiteGroupsUrl);
            this.groups = response.data.Result;
        },
    }
}
</script>
<!-- Styles -->
<style scoped>
.skeleton-icon {
    margin: -10px -1px -5px 0px;
}
.skeleton-label{
    margin: 10px 0px 0px -2px;
}
#menu-icons {
    margin-top: 10px;
}

#menu-icons a {
    color: #212529;
    text-decoration: none;
}

#menu-icons .menu-item {
    min-height: 76px;
    text-align: center;
    margin-bottom: 10px;
}

#menu-icons .menu-item:active {
    transform: scale(0.98);
}

.menu-item .menu-icon {
    height: 53px;
    width: 53px;
    margin: auto;
}

.menu-item .menu-icon.deals {
    color: #fff;
    font-size: 24px;
    padding-top: 8px;
    min-height: 53px;
    background-color: #369ed5;
    border-radius: 50%;
}

.dealCountIcon {
    color: #fff;
    font-size: 12px;
    background-color: #d61515;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 60%;
    bottom: 25%;
}

.menu-icon-row {
    margin-bottom: 10px;
}

#menu-icons div.menu-icon {
    text-align: center;
    max-width: 80px;
}

#menu-icons div.menu-icon img {
    max-width: 100%;
}
</style>
