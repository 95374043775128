<template>
  <div>
    <!-- Top bar -->
    <app-topbar @logout="logout"></app-topbar>

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Update modal -->
    <b-modal id="modalNewVersion" hide-header hide-footer title="Update available">
      <p>A new version is available. Do you want to reload?</p>
      <b-button class="mt-3" variant="outline-secondary" block @click="hideUpdateModal">No</b-button>
      <b-button class="mt-3" variant="outline-primary" block @click="updateReloadApp">Yes</b-button>
    </b-modal>

    <!-- Login Modal -->
    <loginMain @logedIn="logedIn"></loginMain>

    <!-- View -->
    <router-view />
  </div>
</template>
<!-- Scripts-->
<script>
// Imports
import TopbarPartial from "@/components/TopbarPartial.vue";
import SidebarPartial from "@/components/SidebarPartial.vue";
import loginMain from "@/components/login/loginMain";
import { appLocalStorage } from "@/mixins/appLocalStorage";

export default {
  name: "App",
  components: {
    "app-topbar": TopbarPartial,
    "app-sidebar": SidebarPartial,
    loginMain: loginMain,
  },
  mixins: [appLocalStorage],
  methods: {
    onServiceWorkerUpdated(e) {
      // Save new service worker registration in local field
      this.registration = e.detail;

      // Ask if we can update
      this.showUpdateModal();
    },
    updateReloadApp() {
      // Hide the modal
      this.hideUpdateModal();

      if (!this.registration || !this.registration.waiting) {
        return;
      }

      // Clear local storage
      localStorage.clear();

      // Execute the service worker reload
      this.registration.waiting.postMessage("skipWaiting");
    },
    showUpdateModal() {
      this.$bvModal.show("modalNewVersion");
    },
    hideUpdateModal() {
      this.$bvModal.hide("modalNewVersion");
    },
    logedIn(returnUrl) {
      this.$router.push(returnUrl)
      this.$router.go()
    },
    logout() {
      this.RemoveToken();
      this.$router.push('/', () => { });
    }
  },
  data() {
    return {
      refreshing: false,
      registration: null,
    };
  },
  created() {
    // Listen for service worker "updated" event
    document.addEventListener("swUpdated", this.onServiceWorkerUpdated, {
      once: true,
    });

    // Reload the app when the service worker has finished updating
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;

      // Reload the app with the new version
      window.location.reload(true);
    });
  },
};
</script>
<style>
html {
  background-color: #fff;
  font-family: Roboto;
  /* Make text non-selectable*/
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}

body {
  /* Disables pull-to-refresh. */
  overscroll-behavior-y: contain;
  background-color: #fffefe !important;
}

body>div>nav {
  position: fixed !important;
  z-index: 2;
  width: 100%;
}

body>div>nav+* {
  padding-top: 56px !important;
}

#main-breadcrumb {
  position: fixed;
  z-index: 3;
  width: 100%;
}

#main-breadcrumb+.view-container {
  padding-top: 25px !important;
}
</style>
